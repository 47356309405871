import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import timezones from "../../../features/settings/timezones.json";

const TimezoneDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  width,
  fluid,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  const options = timezones.map((timezone) => ({
    key: timezone.id,
    text: timezone.title,
    value: timezone.id,
  }));

  return (
    <Dropdown
      id={id || "dropdown-timezone"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      fluid={fluid}
      width={width}
      search
      selection
      placeholder="Fuso horário"
      label="Fuso horário"
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      deburr
    />
  );
};

TimezoneDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default TimezoneDropdown;
