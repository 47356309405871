/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboardService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { ChatType } from "./chats.slice";

export enum DashcardType {
  CHAT_OPEN = "CHAT_OPEN",
  CHAT_WAITING_CONTACT = "CHAT_WAITING_CONTACT",
  CHAT_FINISHED_INACTIVITY = "CHAT_FINISHED_INACTIVITY",
  CHAT_WAITING_AGENT = "CHAT_WAITING_AGENT",
  CHAT_HOURS_TOTAL = "CHAT_HOURS_TOTAL",
  MESSAGE_RECEIVED = "MESSAGE_RECEIVED",
  MESSAGE_SENDED = "MESSAGE_SENDED",
  CHAT_TEAM_AGENT = "CHAT_TEAM_AGENT",
  CHAT_FIRST_MESSAGE = "CHAT_FIRST_MESSAGE",
  CHAT_BACKLOG_TIME = "CHAT_BACKLOG_TIME",
  // CHAT_BY_CONTACT = "CHAT_BY_CONTACT",
  CHAT_BY_BUSY_HOUR = "CHAT_BY_BUSY_HOUR",
  CHAT_BY_AVG_TIME = "CHAT_BY_AVG_TIME",
}

export interface IDashcard {
  _id: string;
  user: string;
  dashcard: {
    type: DashcardType;
    _id: string;
    data:
      | {
          series: (number | string)[];
          labels: (number | string)[];
        }
      | {
          series: {
            name: string;
            data: (number | string)[];
          }[];
          xaxis: {
            categories: string[];
          };
        };
  };
  active: boolean;
  tenant: string;
  order: number;
  deleted: boolean;
  updated_at?: string;
  created_at: string;
  deleted_at?: string;
}

export type Dashboards = IDashcard[];

export interface INewCustomer {
  name: string;
}

export interface IFilterDashboard {
  teams: string[];
  users: string[];
  period: object | [];
  types: ChatType[];
  tags: string[];
}

interface DashboardState extends AsyncState {
  isLoadingDashboard: boolean;
  dashboard: Dashboards;
  dashboardFilterOptions?: IFilterDashboard;
}

const initialState: DashboardState = {
  dashboard: [],
  dashboardFilterOptions: undefined,
  isLoadingDashboard: false,
  isSuccess: false,
  isError: false,
};

export const getSearchDashboard = createAsyncThunk(
  "dashboard/search",
  async (
    { filter, timezone }: { filter: IFilterDashboard; timezone: string },
    thunkAPI
  ) => {
    try {
      return await dashboardService.search({ filter, timezone });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchDashboard.pending, (state) => {
        state.isLoadingDashboard = true;
      })
      .addCase(getSearchDashboard.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.dashboard = action.payload || [];
        state.isLoadingDashboard = false;
      })
      .addCase(getSearchDashboard.rejected, (state) => {
        state.isError = true;
        state.dashboard = [];
        state.isLoadingDashboard = false;
      });
  },
});

export default dashboardSlice.reducer;
export const { logout } = dashboardSlice.actions;
