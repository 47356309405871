import { IFilterDashboard } from "../slices/dashboard.slice";
import instances from "./instances";

const search = async ({
  filter,
  timezone,
}: {
  filter: IFilterDashboard;
  timezone: string;
}) => {
  try {
    const data = { filter, timezone };
    const response = await instances.instanceDashcard.post("/cards", data);
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const dashboardService = {
  search,
};

export default dashboardService;
