/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { offlinemessagesService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { ITeam } from "./teams.slice";

export enum IOfflineType {
  WIDGET = "WIDGET",
  WHATSAPP = "WHATSAPP",
  AGIBOT = "AGIBOT",
}
export interface IOfflinemessageType {
  type?: IOfflineType;
  content?: string;
}

export interface IOfflinemessage {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  active?: boolean;
  name?: string;
  email?: string;
  message?: IOfflinemessageType[];
  default?: boolean;
}

export interface IOfflinemessageItem {
  offlinemessage?: IOfflinemessage;
  offlinecontent?: IOfflinemessageType[];
  teams?: ITeam[];
  user?: IUser;
  message?: string;
  read?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
}

export type Offlinemessages = IOfflinemessage[];

export interface INewOfflinemessage {
  name: string;
}

interface OfflinemessagesState extends AsyncState {
  isLoadingOfflinemessages: boolean;
  isLoadingDropdownOfflinemessages: boolean;
  isCreatingOfflinemessages: boolean;
  isRemoving: boolean;
  offlinemessages: Offlinemessages;
  totalOfflinemessages: number;
  selectedOfflinemessage: IOfflinemessage | null;
}

const initialState: OfflinemessagesState = {
  offlinemessages: [],
  totalOfflinemessages: 0,
  selectedOfflinemessage: null,
  isRemoving: false,
  isLoadingOfflinemessages: false,
  isLoadingDropdownOfflinemessages: false,
  isCreatingOfflinemessages: false,
  isSuccess: false,
  isError: false,
};

export interface IFilterOfflinemessage {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}

export const getSearchOfflinemessages = createAsyncThunk(
  "offlinemessages/search",
  async (filterOfflinemessage: IFilterOfflinemessage, thunkAPI) => {
    try {
      return await offlinemessagesService.search(filterOfflinemessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectOfflinemessage = createAsyncThunk(
  "offlinemessages/selectOfflinemessage",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await offlinemessagesService.offlinemessage({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateOfflinemessage = createAsyncThunk(
  "offlinemessages/update",
  async (
    {
      _offlinemessage,
      noToast,
    }: { _offlinemessage: IOfflinemessage; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await offlinemessagesService.update({ _offlinemessage });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteOfflinemessage = createAsyncThunk(
  "offlinemessages/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await offlinemessagesService.deleteOfflinemessages({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createOfflinemessage = createAsyncThunk(
  "offlinemessages/create",
  async (newOfflinemessage: INewOfflinemessage, thunkAPI) => {
    try {
      return await offlinemessagesService.create(newOfflinemessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const offlinemessagesSlice = createSlice({
  name: "offlinemessages",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    selectOfflinemessages(state, action: PayloadAction<IOfflinemessage[]>) {
      state.offlinemessages = action.payload || [];
    },
    selectTotalOfflinemessages(state, action: PayloadAction<number>) {
      state.totalOfflinemessages = action.payload || 0;
    },
    reselectOfflinemessage(
      state,
      action: PayloadAction<IOfflinemessage | null>
    ) {
      state.selectedOfflinemessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // OFFLINEMESSAGES
      .addCase(getSearchOfflinemessages.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingOfflinemessages = true;
        }
        state.isLoadingDropdownOfflinemessages = true;
      })
      .addCase(getSearchOfflinemessages.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.offlinemessages = action.payload.results || [];
        // if (state.totalOfflinemessages <= action.payload.count)
        state.totalOfflinemessages = action.payload.count;
        state.isLoadingOfflinemessages = false;
        state.isLoadingDropdownOfflinemessages = false;
      })
      .addCase(getSearchOfflinemessages.rejected, (state) => {
        state.isError = true;
        state.offlinemessages = [];
        state.isLoadingOfflinemessages = false;
        state.isLoadingDropdownOfflinemessages = false;
      })
      // SHOW CUSTOMER
      .addCase(selectOfflinemessage.pending, (state) => {
        state.isLoadingOfflinemessages = true;
      })
      .addCase(selectOfflinemessage.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          state.selectedOfflinemessage = action.payload;
        }
        state.isLoadingOfflinemessages = false;
      })
      .addCase(selectOfflinemessage.rejected, (state) => {
        state.isError = true;
        state.selectedOfflinemessage = null;
        state.isLoadingOfflinemessages = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateOfflinemessage.pending, (/* state */) => {
        // state.isLoadingOfflinemessages = true;
      })
      .addCase(updateOfflinemessage.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingOfflinemessages = false;
      })
      .addCase(updateOfflinemessage.rejected, (state) => {
        state.isError = true;
        state.selectedOfflinemessage = null;
        state.isLoadingOfflinemessages = false;
      })
      .addCase(createOfflinemessage.pending, (state) => {
        state.isCreatingOfflinemessages = true;
      })
      .addCase(createOfflinemessage.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Mensagem criada com sucesso!`,
          });
          const newState = state.offlinemessages;
          newState.push(action.payload);
          state.offlinemessages = newState;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe uma mensagem com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingOfflinemessages = false;
      })
      .addCase(createOfflinemessage.rejected, (state) => {
        state.isError = true;
        state.isCreatingOfflinemessages = false;
      })
      .addCase(deleteOfflinemessage.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteOfflinemessage.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteOfflinemessage.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default offlinemessagesSlice.reducer;
export const {
  logout,
  reselectOfflinemessage,
  selectOfflinemessages,
  selectTotalOfflinemessages,
} = offlinemessagesSlice.actions;
