/* eslint-disable no-case-declarations */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownSearchInputProps, Form, Table } from "semantic-ui-react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Column, useRowSelect, useSortBy, useTable } from "react-table";
import ReactInputMask from "react-input-mask";
import { t } from "i18next";
import { ReactMultiEmail } from "react-multi-email";
import TableBody, { cellObject } from "./Tables/TableBody.component";
import {
  reselectUser,
  update,
  uploadAvatar,
  userdata,
} from "../../slices/auth.slice";
import { Files } from "../models/interfaces/file.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import {
  IUser,
  RoleUser,
  reselectContact,
  reselectAgent,
  selectUser,
  updateUser,
} from "../../slices/users.slice";
// import PhoneMaskInput from "./PhoneMaskInput.component";
import {
  AssignedType,
  ITeam,
  UsersSettings,
  reselectTeam,
  updateTeam,
} from "../../slices/teams.slice";
import { ICustomer, reselectCustomer } from "../../slices/customers.slice";
import useInput from "../../hooks/useInput";
import { validateNameLength } from "../utils/validation/length";
import removeDuplicates from "../utils/removeDuplicates";
import Toggle from "./Toggle.component";
import IndeterminateCheckbox from "./Tables/IndeterminateCheckbox.component";
import { OFFICEHOURS_COLUMNS, SURVEYRATING_COLUMNS } from "./Tables/columns";
import TableHeader from "./Tables/TableHeader.component";
import FormAvatar from "./FormAvatar.component";
import {
  DropdownUser,
  getSettings,
  reselectSettings,
  updateSettings,
} from "../../slices/settings.slice";
import Button from "./Buttons/Button.component";
import RelatedInstance from "./RelatedInstance.component";
import WidgetInput from "./WidgetInput.component";
import {
  IAgideskTeam,
  agideskImport,
  getAgideskTeams,
  reselectExternalappAgidesk,
  reselectExternalappWhatsApp,
  reselectExternalappBotmaker,
  reselectExternalappVtex,
  IAppPublicServicesItems,
  ExternalAppType,
  updateExternalapps,
} from "../../slices/externalapps.slice";
import TeamsUsers from "./Agidesk/Import/TeamsUsers.component";
import RelationModal from "./Modals/RelationModal.component";
import { ddOptions } from "../models/interfaces/dropdownOptions.interface";
import NoAvatar from "./NoAvatar.component";
import Dropdown from "./Dropdown.component";
import PaginationComponent from "./Tables/Pagination.component";
import SelectPerPage from "./Tables/SelectPerPage.component";
import Avatar from "./Avatar.component";
import RtfEditor from "./RtfEditor.component";
import {
  ISurvey,
  ISurveyRating,
  reselectSurvey,
  updateSurvey,
} from "../../slices/surveys.slice";
import {
  chatsService,
  officehoursService,
  surveysService,
  offlinemessagesService,
  usersService,
} from "../../services";
import {
  IOfficehour,
  SettingOfficehours,
  reselectOfficehour,
  updateOfficehour,
} from "../../slices/officehours.slice";
import { reselectHoliday } from "../../slices/holidays.slice";
import {
  ActiveDropdown,
  AssignedtypeDropdown,
  DaysDropdown,
  MonthsDropdown,
  RolesDropdown,
  SegmentDropdown,
  ServicerestrictDropdown,
  SurveyTypesDropdown,
  YearsDropdown,
  ContactTagsDropdown,
} from "./Dropdowns";
import parseBlurTime from "../utils/parseBlurTime";
import PopupComponent from "./Popup.component";
import {
  ChatAdmin,
  ChatGroupRole,
  ChatType,
  IChat,
  changeChat,
  chatFirst,
  reselectChat,
} from "../../slices/chats.slice";
import { IMessage, InternalMessageType } from "../../slices/messages.slice";
import useSendInternalMessage from "../../hooks/useSendInternalMessage";

import {
  IOfflineType,
  IOfflinemessage,
  reselectOfflinemessage,
} from "../../slices/offlinemessages.slice";
import { ITag } from "../../slices/tags.slice";
import ErrorLabel from "../../features/auth/components/Forms/ErrorLabel.component";
// import findInObject from "../utils/findInObject";
import useUserIsAdmin from "../../hooks/useUserIsAdmin";
import CopyButton from "./Buttons/CopyButton.component";
import ShowHideButton from "./Buttons/ShowHideButton.component";
import useLGPD from "../../hooks/useLGPD";
import PhoneList from "./Agibot/PhoneList.component";
import PhoneFlagInput from "./PhoneFlagInput.component";
import { ProgramedExcluding } from "./SectionElements/ProgramedExcluding";
import CategoriesTopicsSelectionComponent from "./Agidesk/CategoriesTopicsSelection.component";
import { AgideskChatFinish } from "./SectionElements/AgideskChatFinish.component";
import LinkButton from "./Buttons/LinkButton.component";

const SectionElement = ({
  instance,
  type,
  widget,
  showModal,
  setShowModal,
  setShowPreviewModal,
  setVisible,
  setSelected,
  setShowChatModal,
  save,
  deletedItems,
  selectedRow,
  setSelectedRow,
  setShowDeleteModal,
  setChatContact,
  setChat,
  surveyRatings,
  toggle,
  setToggle,
}: {
  instance: any;
  setSelected?: any;
  type: string;
  widget?: boolean;
  showPreviewModal?: boolean;
  setShowPreviewModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showModal?: boolean;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowChatModal?: React.Dispatch<React.SetStateAction<boolean>>;
  save?: () => void;
  setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow?: cellObject[];
  setSelectedRow?: React.Dispatch<React.SetStateAction<cellObject[]>>;
  deletedItems?: React.MutableRefObject<string[]>;
  setChatContact?: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  setChat?: React.Dispatch<React.SetStateAction<IChat | null>>;
  surveyRatings?: ISurveyRating[];
  toggle?: boolean;
  setToggle?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { showCode } = useLGPD();
  const { sendInternalMessage } = useSendInternalMessage();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, tenantID } = useAppSelector((state) => state.auth);
  const { selectedChat, selectedHistoryContact } = useAppSelector(
    (state) => state.chats
  );
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { selectedTeam } = useAppSelector((state) => state.teams);
  const { selectedOfficehour } = useAppSelector((state) => state.officehours);
  const { selectedOfflinemessage } = useAppSelector(
    (state) => state.offlinemessages
  );
  const {
    selectedExternalAppWhatsApp,
    selectedExternalAppAgidesk,
    selectedExternalAppBotmaker,
    selectedExternalAppVtex,
  } = useAppSelector((state) => state.externalapps);
  const [optionsInstance, setOptionsInstance] = useState<ddOptions[]>([]);
  const [time, setTime] = useState<string>("");
  const _changingValue = useRef(false);
  const [showHide, setShowHide] = useState<{
    "agidesk-appkey": boolean;
    "agibot-appkey": boolean;
    whatsappAppPhone: boolean;
    whatsAppBusinessAccountId: boolean;
    whatsAppKey: boolean;
    "vtex-appkey": boolean;
  }>({
    "agidesk-appkey": false,
    "agibot-appkey": false,
    whatsappAppPhone: false,
    whatsAppBusinessAccountId: false,
    whatsAppKey: false,
    "vtex-appkey": false,
  });

  const [showImport, setShowImport] = useState<boolean>(false);
  const [valueInstance] = useState<string[]>([]);
  const [importTeamUsers, setImportTeamUsers] = useState<IAgideskTeam[]>([]);
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);

  const [skipTeamsPage, setSkipTeamsPage] = useState<number>(0);
  const [activeTeamsPage, setActiveTeamsPage] = useState<number>(1);
  const [limitAgideskTeams, setLimitAgideskTeams] = useState<number>(5);

  const [focusId, setFocusId] = useState<string>("");

  const { inputBlurHandler: nameBlurHandler } = useInput(validateNameLength);
  const [chatName, setChatName] = useState<string>("");

  const getNameGroup = () => {
    const includedInGroup =
      instance?.groupchat &&
      instance?.groupsettings
        ?.filter((_gs: ChatAdmin) => _gs.active === true)
        ?.map((_u: ChatAdmin) =>
          typeof _u.user?._id !== "undefined" ? _u.user?._id : _u.user
        )
        .includes(user?._id);
    const userInGroup = instance?.groupsettings?.find((_gs: ChatAdmin) =>
      typeof _gs.user?._id !== "undefined"
        ? _gs.user?._id === user?._id
        : _gs.user === user?._id
    );
    const name = !(instance?.groupchat && includedInGroup)
      ? userInGroup.chatname
      : instance?.name;
    setChatName(name);
  };

  useEffect(() => {
    if (instance?.groupchat) {
      getNameGroup();
    }
  }, [instance?.name]);

  useEffect(() => {
    if (typeof setChat !== "undefined" && chatName.length <= 30) {
      setChat({
        ...instance,
        name: chatName,
      });
    }
  }, [chatName]);

  const columns: readonly Column<object>[] = type.includes(
    "settings-surveyrating"
  )
    ? useMemo(() => SURVEYRATING_COLUMNS, [])
    : useMemo(() => OFFICEHOURS_COLUMNS, []);

  type weekdayOptions = {
    [key: string]: number;
  };
  const order: weekdayOptions = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  const data: readonly object[] = type.includes("settings-surveyrating")
    ? useMemo(
        () =>
          type.includes("settings-surveyrating")
            ? instance
            : surveyRatings || [],
        [surveyRatings]
      )
    : useMemo(() => {
        if (type === "officehours") {
          const _items =
            typeof instance !== "undefined" && instance.length > 0
              ? [...instance]
              : [];
          return _items.length > 0
            ? _items
                ?.sort((a: SettingOfficehours, b: SettingOfficehours) =>
                  a?.start.localeCompare(b?.start)
                )
                ?.sort(
                  (a: SettingOfficehours, b: SettingOfficehours) =>
                    order[a?.weekday] - order[b?.weekday]
                )
            : [];
        }
        if (typeof selectedOfficehour?.officehours !== "undefined") {
          const _items =
            typeof selectedOfficehour.officehours !== "undefined" &&
            selectedOfficehour.officehours.length > 0
              ? [...selectedOfficehour.officehours]
              : [];
          return _items.length > 0
            ? _items
                ?.sort((a: SettingOfficehours, b: SettingOfficehours) =>
                  a?.start.localeCompare(b?.start)
                )
                ?.sort(
                  (a: SettingOfficehours, b: SettingOfficehours) =>
                    order[a?.weekday] - order[b?.weekday]
                ) || []
            : [];
        }
        return [];
      }, [selectedOfficehour?.officehours]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsHook) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps({
                  title: "Selecionar todos",
                })}
              />
            ),
            Cell: ({ row }) => (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps({
                  title: "Selecionar",
                })}
              />
            ),
          },
          ...columnsHook,
        ];
      });
    }
  );

  const handleSave = async (id: string | null) => {
    if (instance) {
      switch (type) {
        case "profile-info-widget":
        case "profile-info":
          await dispatch(
            update({
              _updateUser: {
                ...instance,
                teams: instance?.teams?.map((_t: ITeam) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
                tags: instance?.tags?.map((_t: ITag) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
                customers: instance?.customers?.map((_t: ICustomer) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
                avatar: id,
              },
            })
          );
          const _actionUserdata = await dispatch(userdata());
          const _userdata = _actionUserdata.payload as IUser | null | undefined;
          if (_userdata !== null && typeof _userdata?._id !== "undefined") {
            dispatch({
              type: "chats/changeUserChat",
              payload: _userdata,
            });
            dispatch({
              type: "messages/changeUserMessage",
              payload: _userdata,
            });
            dispatch({
              type: "users/changeUser",
              payload: _userdata,
            });
          }
          break;
        case "user-info":
          const actionUser = await dispatch(
            updateUser({
              ...instance,
              teams: instance?.teams?.map((_t: ITeam) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              tags: instance?.tags?.map((_t: ITag) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              customers: instance?.customers?.map((_t: ICustomer) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              avatar: id,
            })
          );
          if (
            actionUser?.payload !== null &&
            typeof actionUser?.payload?._id !== "undefined"
          ) {
            const { payload } = await dispatch(
              selectUser({ _id: instance?._id || "" })
            );
            if (payload !== null && typeof payload?._id !== "undefined") {
              dispatch({
                type: "chats/changeUserChat",
                payload,
              });
              dispatch({
                type: "messages/changeUserMessage",
                payload,
              });
              dispatch({
                type: "users/changeUser",
                payload,
              });
            }
          }
          break;
        case "contact-info":
          const action = await dispatch(
            updateUser({
              ...instance,
              teams: instance?.teams?.map((_t: ITeam) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              tags: instance?.tags?.map((_t: ITag) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              customers: instance?.customers?.map((_t: ICustomer) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              avatar: id,
            })
          );
          if (
            action?.payload !== null &&
            typeof action?.payload?._id !== "undefined"
          ) {
            const { payload } = await dispatch(
              selectUser({
                _id: instance?._id || "",
                isContact: true,
              })
            );
            if (payload !== null && typeof payload?._id !== "undefined") {
              dispatch({
                type: "chats/changeUserChat",
                payload,
              });
              dispatch({
                type: "messages/changeUserMessage",
                payload,
              });
              dispatch({
                type: "users/changeUser",
                payload,
              });
            }
          }
          break;
        case "settings-defaultbot":
          await dispatch(
            updateSettings({
              _settings: {
                ...selectedSettings,
                defaultteam: selectedSettings?.defaultteam?.map(
                  (_t: ITeam | string) => {
                    if (typeof _t === "string") return _t;
                    return _t._id;
                  }
                ),
                defaultagent: selectedSettings?.defaultagent?._id,
                offlinemessage: selectedSettings?.offlinemessage?._id,
                company: {
                  ...selectedSettings?.company,
                  avatar: selectedSettings?.company?.avatar?._id,
                },
                officehour: selectedSettings?.officehour?._id,
                defaultbot: {
                  ...instance,
                  avatar: id,
                },
                lgpd:
                  typeof selectedSettings?.lgpd !== "undefined" &&
                  selectedSettings?.lgpd.length > 0
                    ? selectedSettings?.lgpd
                    : [
                        {
                          active: false,
                          roles: [RoleUser.ADMINISTRATOR],
                          field: "code",
                        },
                      ],
              },
            })
          );
          break;
        case "groupchat-info":
          let _message: IMessage | undefined | null;
          if (instance && typeof instance?._id !== "undefined") {
            const _chat = await chatsService.updateGroup({
              _chat: {
                _id: instance._id,
                avatar: id,
                name: instance.name,
                users: instance.users?.map((_u: IUser) => _u._id as string),
                groupsettings: instance.groupsettings,
              },
            });
            if (typeof _chat !== "undefined" && _chat !== null) {
              _message = await sendInternalMessage({
                type: InternalMessageType.EDIT_GROUP,
                message: {
                  chat: _chat,
                  content: `<b>[${user?._id}]</b> alterou a imagem do grupo`,
                },
              });
              dispatch(
                chatFirst({
                  chat: {
                    ..._chat,
                    lastmessage:
                      typeof _message !== "undefined" && _message
                        ? _message
                        : undefined,
                    updated_at: _chat.updated_at,
                    updated_by: _chat.updated_by,
                  },
                  type: "mine",
                })
              );
              dispatch(
                reselectChat({
                  chat: {
                    ..._chat,
                    lastmessage:
                      typeof _message !== "undefined" && _message
                        ? _message
                        : undefined,
                    updated_at: _chat.updated_at,
                    updated_by: _chat.updated_by,
                  },
                })
              );
              dispatch(
                changeChat({
                  chat: {
                    ..._chat,
                    lastmessage:
                      typeof _message !== "undefined" && _message
                        ? _message
                        : undefined,
                    updated_at: _chat.updated_at,
                    updated_by: _chat.updated_by,
                  },
                })
              );
            }
          }
          break;
        case "team-info":
        case "customer-info":
        case "agidesk-info":
        case "settings-company":
        case "agibot-info":
          break;
        default:
          break;
      }
    }
  };

  const handleDeleteAvatar = async () => {
    if (instance) {
      switch (type) {
        case "profile-info-widget":
        case "profile-info":
          dispatch(
            reselectUser({
              ...instance,
              avatar: undefined,
            })
          );
          break;
        case "user-info":
          dispatch(
            reselectAgent({
              ...instance,
              avatar: undefined,
            })
          );
          break;
        case "contact-info":
          dispatch(
            reselectContact({
              ...instance,
              avatar: undefined,
            })
          );
          break;
        case "settings-defaultbot":
          dispatch(
            reselectSettings({
              ...selectedSettings,
              defaultbot: {
                ...instance,
                avatar: undefined,
              },
            })
          );
          break;
        case "settings-company":
          dispatch(
            reselectSettings({
              ...selectedSettings,
              company: {
                ...instance,
                avatar: undefined,
              },
            })
          );
          break;
        case "groupchat-info":
        case "team-info":
        case "customer-info":
        case "agidesk-info":
        case "agibot-info":
        default:
          break;
      }
      handleSave(null);
    }
  };

  const handleAvatarChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }
    const _file = input.files[0];
    const { payload } = await dispatch(uploadAvatar([_file]));
    if (instance && payload && typeof payload !== "string") {
      const createdFile = payload as Files;
      switch (type) {
        case "profile-info-widget":
        case "profile-info":
          dispatch(
            reselectUser({
              ...instance,
              avatar: createdFile[0],
            })
          );
          break;
        case "user-info":
          if (setChatContact) {
            setChatContact({
              ...instance,
              avatar: createdFile[0],
            });
          }
          dispatch(
            reselectAgent({
              ...instance,
              avatar: createdFile[0],
            })
          );
          break;
        case "contact-info":
          if (setChatContact) {
            setChatContact({
              ...instance,
              avatar: createdFile[0],
            });
          }
          dispatch(
            reselectContact({
              ...instance,
              avatar: createdFile[0],
            })
          );
          break;
        case "settings-defaultbot":
          dispatch(
            reselectSettings({
              ...selectedSettings,
              defaultbot: {
                ...instance,
                avatar: createdFile[0],
              },
            })
          );
          break;
        case "settings-company":
          dispatch(
            reselectSettings({
              ...selectedSettings,
              company: {
                ...instance,
                avatar: createdFile[0],
              },
            })
          );
          break;
        case "groupchat-info":
        case "team-info":
        case "customer-info":
        case "agidesk-info":
        case "agibot-info":
        default:
          break;
      }
      handleSave(createdFile[0]?._id || "");
    }
  };

  const handleOnDragEnd = async (result: DropResult) => {
    if (instance?.chatusers) {
      if (!result.destination || !isAdmin) return;

      const items = Array.from(instance.chatusers as UsersSettings);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      let _reorderedList = items.map((item, index) => {
        return { ...item, order: +index + 1 };
      });

      _reorderedList = removeDuplicates(_reorderedList, "user");

      dispatch(
        reselectTeam({
          ...instance,
          chatusers: _reorderedList,
        })
      );
      await dispatch(
        updateTeam({
          _team: {
            ...instance,
            officehour: instance?.officehour?._id,
            survey: instance?.survey?._id,
            chatusers: _reorderedList,
          },
        })
      );
    }
  };

  const showAvailableUsersList = () => {
    const _users = [];
    if (instance?.chatusers) {
      let _chatUsers = [];
      if (instance?.chatusers && instance?.chatusers.length > 0) {
        for (const chatUser of instance.chatusers) {
          let _value = instance?.users?.find(
            (_user: IUser) => _user._id === chatUser.user
          );
          if (_value !== undefined) {
            _value = { ..._value, order: chatUser?.order };
            _chatUsers.push(_value);
          }
        }
      }
      _chatUsers = removeDuplicates(_chatUsers, "_id");
      for (const index in _chatUsers) {
        if (Object.prototype.hasOwnProperty.call(_chatUsers, index)) {
          const chatUserFound = _chatUsers[index];
          _users.push(
            <Draggable
              key={chatUserFound?._id}
              draggableId={chatUserFound?._id || ""}
              index={+index}
            >
              {(provided) => {
                return (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="py-2 select-none"
                    style={{
                      ...provided.draggableProps.style,
                    }}
                  >
                    <div
                      role="presentation"
                      title={chatUserFound.name}
                      onClick={() => {
                        if (chatUserFound?._id !== user?._id) {
                          navigate(`/settings/user/${chatUserFound._id}`);
                        } else {
                          navigate("/settings/profile");
                        }
                      }}
                      key={chatUserFound._id}
                      className={`cursor-pointer flex items-center justify-between px-5 py-3 shadow rounded-md max-w-full border-l-[6px] ${
                        !chatUserFound.deleted ? "border-green" : "border-red"
                      }`}
                    >
                      <div className="flex">
                        <div className="w-[32px] h-[32px]">
                          {chatUserFound.avatar &&
                          chatUserFound.avatar?.path &&
                          chatUserFound.avatar?.name ? (
                            <Avatar
                              path={chatUserFound?.avatar?.path}
                              name={chatUserFound?.avatar?.name}
                              title={chatUserFound?.name}
                            />
                          ) : (
                            <NoAvatar
                              displaytext={chatUserFound?.name || "--"}
                              elementClass="p-0"
                              labelClass="text-[12px]"
                            />
                          )}
                        </div>
                        <div className="ml-2 text-justify">
                          <p>{chatUserFound.name}</p>
                          <p className="text-sm text-gray-400">
                            {chatUserFound.email || "-"}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="font-semibold text-gray-text">
                          {chatUserFound.order || +index + 1}
                        </p>
                      </div>
                    </div>
                  </li>
                );
              }}
            </Draggable>
          );
        }
      }
    }
    return _users;
  };

  const handleOnChangeInfo = async ({
    field,
    value,
  }: {
    field: string;
    value: any;
  }) => {
    switch (type) {
      case "profile-info-widget":
      case "profile-info":
        dispatch(
          reselectUser({
            ...instance,
            [field]: value,
          })
        );
        break;
      case "user-info":
        if (field !== "email") {
          dispatch(
            reselectAgent({
              ...instance,
              [field]: value,
            })
          );
          if (setChatContact) {
            setChatContact({
              ...instance,
              [field]: value,
            });
          }
        }
        break;
      case "contact-info":
        dispatch(
          reselectContact({
            ...instance,
            [field]: value,
          })
        );
        if (setChatContact) {
          setChatContact({
            ...instance,
            [field]: value,
          });
        }
        break;
      case "team-info":
      case "team-chatusers":
        if (field === "assignedtype") {
          dispatch(
            reselectTeam({
              ...instance,
              assignedtype: value,
              chatlimit: false,
              chatlimitsize: 0,
            })
          );
        } else if (field === "officehour" || field === "survey") {
          let _team = {
            ...selectedTeam,
            officehour: selectedTeam?.officehour?._id,
            survey: selectedTeam?.survey?._id,
          };
          _team = {
            ..._team,
            [field]: value._id,
          };
          const { payload } = await dispatch(updateTeam({ _team }));
          if (
            payload !== null &&
            typeof payload !== "undefined" &&
            typeof payload?.message !== "string"
          ) {
            dispatch(
              reselectTeam({
                ...selectedTeam,
                [field]: value,
                updated_at: payload.updated_at,
                updated_by: payload.updated_by,
              })
            );
          }
        } else if (field === "chatlimit" || field === "chatlimitsize") {
          if (field === "chatlimit" && value === false) {
            dispatch(
              reselectTeam({
                ...instance,
                [field]: value,
                chatlimitsize: 0,
              })
            );
          } else {
            dispatch(
              reselectTeam({
                ...instance,
                [field]: value,
              })
            );
          }
        } else {
          dispatch(
            reselectTeam({
              ...instance,
              [field]: value,
            })
          );
        }
        break;
      case "customer-info":
        dispatch(
          reselectCustomer({
            ...instance,
            [field]: value,
          })
        );
        break;
      case "officehour-info":
        dispatch(
          reselectOfficehour({
            ...instance,
            [field]: value,
          })
        );
        break;
      case "holiday-info":
        dispatch(
          reselectHoliday({
            ...instance,
            [field]: value,
          })
        );
        break;
      case "offlinemessage-info":
      case "offlinemessage-email":
        dispatch(
          reselectOfflinemessage({
            ...selectedOfflinemessage,
            [field]: value,
          })
        );
        break;
      case "agidesk-info":
      case "agidesk-login":
        if (
          value !== instance[field] &&
          ["url", "slug", "email", "appkey"].includes(field)
        ) {
          _changingValue.current = true;
        }
        if (instance && typeof value === "boolean" && field === "active") {
          dispatch(
            reselectExternalappAgidesk({
              ...instance,
              [field]: value,
            })
          );
        } else if (field === "enablelogin" && value === false) {
          dispatch(
            reselectExternalappAgidesk({
              ...instance,
              fields: {
                ...instance.fields,
                [field]: value,
                displaytitlelogin: "",
              },
            })
          );
        } else {
          dispatch(
            reselectExternalappAgidesk({
              ...instance,
              fields: {
                ...instance.fields,
                [field]: value,
              },
            })
          );
        }
        break;
      case "agidesk-ticket":
        if (
          instance &&
          ![
            "title",
            "subject",
            "includechat",
            "internal",
            "openingsolved",
          ].includes(field)
        ) {
          if (field === "enablechat" && value === false) {
            dispatch(
              reselectExternalappAgidesk({
                ...instance,
                fields: {
                  ...instance.fields,
                  [field]: value,
                  displaytitlechat: "",
                  servicerestrict: false,
                  ticketinfo: {
                    title: "",
                    subject: "",
                    includechat: false,
                    internal: false,
                    openingsolved: false,
                  },
                  service: undefined,
                },
              })
            );
          } else {
            dispatch(
              reselectExternalappAgidesk({
                ...instance,
                fields: {
                  ...instance.fields,
                  [field]: value,
                },
              })
            );
          }
        } else {
          dispatch(
            reselectExternalappAgidesk({
              ...instance,
              fields: {
                ...instance.fields,
                ticketinfo: {
                  ...instance.fields.ticketinfo,
                  [field]: value,
                },
              },
            })
          );
        }
        break;
      case "agidesk-chat-finish":
        // TO DO
        if (
          instance &&
          !["title", "includechat", "internal", "openingsolved"].includes(field)
        ) {
          if (
            (field === "ticketfinish" || field === "ticketfinishinactive") &&
            value === false
          ) {
            dispatch(
              reselectExternalappAgidesk({
                ...instance,
                fields: {
                  ...instance.fields,
                  [field]: value,
                  ticketfinishinactive: false,
                  ticketfinishinfo: {
                    includechat: false,
                    internal: false,
                    openingsolved: false,
                    title: "",
                  },
                  ticketfinishinactiveinfo: {
                    includechat: false,
                    internal: false,
                    openingsolved: false,
                    title: "",
                  },
                  ticketfinishservice: undefined,
                  ticketfinishteam: undefined,
                  ticketfinishinactiveservice: undefined,
                  ticketfinishinactiveteam: undefined,
                },
              })
            );
          } else {
            dispatch(
              reselectExternalappAgidesk({
                ...instance,
                fields: {
                  ...instance.fields,
                  [field]: value,
                },
              })
            );
          }
        } else {
          dispatch(
            reselectExternalappAgidesk({
              ...instance,
              fields: {
                ...instance.fields,
                ticketfinishinfo: {
                  ...instance.fields.ticketfinishinfo,
                  [field]: value,
                },
              },
            })
          );
        }
        break;
      case "settings-company":
        if (instance) {
          dispatch(
            reselectSettings({
              ...selectedSettings,
              company: {
                ...instance,
                [field]: value.toString(),
              },
            })
          );
        }
        break;
      case "survey-info":
      case "survey-content":
        if (["survey", "sendsurvey"].includes(field)) {
          const _surveyData = {
            ...instance,
            teams: instance?.teams?.map((_t: ITeam) => {
              if (typeof _t === "string") return _t;
              return _t._id;
            }),
            [field]: value,
          };
          const { payload } = await dispatch(
            updateSurvey({
              _survey: _surveyData,
            })
          );
          if (typeof payload?._id !== "undefined") {
            dispatch(
              reselectSurvey({ ..._surveyData, teams: instance?.teams })
            );
          }
        } else {
          const _surveyData = {
            ...instance,
            _id: instance?._id,
            name: field === "name" ? value : instance?.name,
            active: field === "active" ? value : instance?.active,
            type: field === "type" ? value : instance?.type,
            content: field === "content" ? value : instance?.content,
            survey: field === "survey" ? value : instance?.survey,
            sendsurvey: field === "sendsurvey" ? value : instance?.sendsurvey,
            default: field === "default" ? value : instance?.default,
          };
          dispatch(reselectSurvey({ ..._surveyData, teams: instance?.teams }));
        }
        break;
      case "settings-defaultbot":
        if (field === "defaultteam") {
          const _value = value as any;
          // if (_value.length <= 10) {
          optionsInstance.forEach((_optTeam) => {
            _optTeam.disabled = false;
            return false;
          });
          dispatch(
            reselectSettings({
              ...selectedSettings,
              [field]: _value,
            })
          );
          // if (_value.length === 10) {
          //   customToast({
          //     type: IToastType.WARNING,
          //     message: "Atenção: O limite máximo de equipes é 10!",
          //   });
          // }
          // } else {
          //   optionsInstance.forEach((_optTeam) => {
          //     _optTeam.disabled = true;
          //     return true;
          //   });
          // }
        } else {
          dispatch(
            reselectSettings({
              ...selectedSettings,
              defaultbot: {
                ...instance,
                [field]: value?.toString(),
              },
            })
          );
        }
        break;
      case "settings-defaultagent":
        const _action = await dispatch(
          updateSettings({
            _settings: {
              ...selectedSettings,
              [field]: value._id,
              officehour: selectedSettings?.officehour?._id,
              offlinemessage: selectedSettings?.offlinemessage?._id,
              company: {
                ...selectedSettings?.company,
                avatar: selectedSettings?.company?.avatar?._id,
              },
              defaultbot: {
                ...selectedSettings?.defaultbot,
                avatar: selectedSettings?.defaultbot?.avatar?._id,
              },
              defaultteam: selectedSettings?.defaultteam?.map((_t) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              lgpd:
                typeof selectedSettings?.lgpd !== "undefined" &&
                selectedSettings?.lgpd.length > 0
                  ? selectedSettings?.lgpd
                  : [
                      {
                        active: false,
                        roles: [RoleUser.ADMINISTRATOR],
                        field: "code",
                      },
                    ],
            },
          })
        );
        if (typeof _action?.payload?.widget !== "undefined") {
          dispatch(
            reselectSettings({
              ...selectedSettings,
              defaultagent: value,
              widget: _action?.payload.widget,
              updated_at: _action?.payload.updated_at,
              updated_by: _action?.payload.updated_by,
            })
          );
        }
        if (typeof _action?.payload?._id !== "undefined") {
          dispatch(getSettings({}));
        }
        break;
      case "settings-inactivetime":
        setTime(value);
        dispatch(
          reselectSettings({
            ...selectedSettings,
            [field]: value.replaceAll("_", "0"),
          })
        );
        break;
      case "settings-survey":
        switch (field) {
          case "sendsurvey":
            dispatch(
              reselectSettings({
                ...selectedSettings,
                sendsurvey: value,
              })
            );
            break;
          case "survey":
            const _settingsSurvey = {
              ...selectedSettings,
              survey: value,
              officehour: selectedSettings?.officehour?._id,
              offlinemessage: selectedSettings?.offlinemessage?._id,
              company: {
                ...selectedSettings?.company,
                avatar: selectedSettings?.company?.avatar?._id,
              },
              defaultbot: {
                ...selectedSettings?.defaultbot,
                avatar: selectedSettings?.defaultbot?.avatar?._id,
              },
              defaultagent: selectedSettings?.defaultagent?._id,
              defaultteam: selectedSettings?.defaultteam?.map((_t) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              lgpd:
                typeof selectedSettings?.lgpd !== "undefined" &&
                selectedSettings?.lgpd.length > 0
                  ? selectedSettings?.lgpd
                  : [
                      {
                        active: false,
                        roles: [RoleUser.ADMINISTRATOR],
                        field: "code",
                      },
                    ],
            };
            const _actionSurvey = await dispatch(
              updateSettings({ _settings: _settingsSurvey })
            );

            if (
              _actionSurvey?.payload !== null &&
              typeof _actionSurvey?.payload !== "undefined" &&
              typeof _actionSurvey?.payload?.message !== "string"
            ) {
              dispatch(
                reselectSettings({
                  ...selectedSettings,
                  survey: value,
                  updated_at: _actionSurvey?.payload.updated_at,
                  updated_by: _actionSurvey?.payload.updated_by,
                })
              );
            }
            break;
          default:
            break;
        }
        break;
      case "settings-officehour":
        const _settingsOfficehours = {
          ...selectedSettings,
          company: {
            ...selectedSettings?.company,
            avatar: selectedSettings?.company?.avatar?._id,
          },
          defaultbot: {
            ...selectedSettings?.defaultbot,
            avatar: selectedSettings?.defaultbot?.avatar?._id,
          },
          officehour: value?._id,
          offlinemessage: selectedSettings?.offlinemessage?._id,
          defaultagent: selectedSettings?.defaultagent?._id,
          defaultteam: selectedSettings?.defaultteam?.map((_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }),
          lgpd:
            typeof selectedSettings?.lgpd !== "undefined" &&
            selectedSettings?.lgpd.length > 0
              ? selectedSettings?.lgpd
              : [
                  {
                    active: false,
                    roles: [RoleUser.ADMINISTRATOR],
                    field: "code",
                  },
                ],
        };
        const _actionOfficehours = await dispatch(
          updateSettings({ _settings: _settingsOfficehours })
        );
        if (
          _actionOfficehours?.payload !== null &&
          typeof _actionOfficehours?.payload !== "undefined" &&
          typeof _actionOfficehours?.payload?.message !== "string"
        ) {
          dispatch(
            reselectSettings({
              ...selectedSettings,
              officehour: value,
              updated_at: _actionOfficehours?.payload.updated_at,
              updated_by: _actionOfficehours?.payload.updated_by,
            })
          );
        }
        break;
      case "settings-offlinemessage":
        const _settings = {
          ...selectedSettings,
          defaultbot: {
            ...selectedSettings?.defaultbot,
            avatar: selectedSettings?.defaultbot?.avatar?._id,
          },
          company: {
            ...selectedSettings?.company,
            avatar: selectedSettings?.company?.avatar?._id,
          },
          offlinemessage: value?._id,
          officehour: selectedSettings?.officehour?._id,
          defaultagent: selectedSettings?.defaultagent?._id,
          defaultteam: selectedSettings?.defaultteam?.map((_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }),
          lgpd:
            typeof selectedSettings?.lgpd !== "undefined" &&
            selectedSettings?.lgpd.length > 0
              ? selectedSettings?.lgpd
              : [
                  {
                    active: false,
                    roles: [RoleUser.ADMINISTRATOR],
                    field: "code",
                  },
                ],
        };
        const { payload } = await dispatch(updateSettings({ _settings }));
        if (
          payload !== null &&
          typeof payload !== "undefined" &&
          typeof payload?.message !== "string"
        ) {
          dispatch(
            reselectSettings({
              ...selectedSettings,
              offlinemessage: value,
              updated_at: payload.updated_at,
              updated_by: payload.updated_by,
            })
          );
        }
        break;
      case "whatsapp-interactive":
        if (instance) {
          dispatch(
            reselectExternalappWhatsApp({
              ...instance,
              fields: {
                ...instance.fields,
                offlinemessage: instance?.fields?.offlinemessage?._id,
                [field]: value.toString(),
              },
            })
          );
        }
        break;
      case "whatsapp-offlinemessage":
        const wppPayload = {
          _id: selectedExternalAppWhatsApp?._id,
          active: selectedExternalAppWhatsApp?.active,
          type: ExternalAppType.WHATSAPP,
          fields: {
            name: selectedExternalAppWhatsApp?.fields?.name,
            displayphone:
              selectedExternalAppWhatsApp?.fields?.displayphone?.replace(
                /[^0-9.]/g,
                ""
              ),
            phone: selectedExternalAppWhatsApp?.fields?.phone,
            key: selectedExternalAppWhatsApp?.fields?.key,
            verifytoken: selectedExternalAppWhatsApp?._id,
            wabaid: selectedExternalAppWhatsApp?.fields?.wabaid,
            validate: selectedExternalAppWhatsApp?.fields?.validate,
            header: selectedExternalAppWhatsApp?.fields?.header,
            body: selectedExternalAppWhatsApp?.fields?.body,
            button: selectedExternalAppWhatsApp?.fields?.button,
            offlinemessage: value._id,
            defaultteam: selectedExternalAppWhatsApp?.fields?.defaultteam?.map(
              (_t) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }
            ),
          },
        };
        const _actionWpp = await dispatch(
          updateExternalapps({ _externalapp: wppPayload })
        );
        if (typeof _actionWpp?.payload?._id !== "undefined") {
          dispatch(
            reselectExternalappWhatsApp({
              ...selectedExternalAppWhatsApp,
              fields: {
                ...selectedExternalAppWhatsApp.fields,
                offlinemessage: value,
              },
              updated_at: _actionWpp?.payload?.updated_at,
              updated_by: _actionWpp?.payload?.updated_by,
            })
          );
        }
        break;
      case "agibot-offlinemessage":
        const agibotPayload = {
          ...selectedExternalAppBotmaker,
          fields: {
            ...selectedExternalAppBotmaker?.fields,
            offlinemessage: value._id,
          },
        };
        const actionAgibot = await dispatch(
          updateExternalapps({ _externalapp: agibotPayload })
        );
        if (actionAgibot?.payload?._id) {
          dispatch(
            reselectExternalappBotmaker({
              ...selectedExternalAppBotmaker,
              fields: {
                ...selectedExternalAppBotmaker.fields,
                offlinemessage: value,
              },
              updated_at: actionAgibot?.payload?.updated_at,
              updated_by: actionAgibot?.payload?.updated_by,
            })
          );
        }
        break;
      case "agibot-info":
        if (
          value !== instance[field] &&
          ["name", "appkey", "apiurl", "botid"].includes(field)
        ) {
          _changingValue.current = true;
        }
        if (instance && typeof value === "boolean" && field === "active") {
          dispatch(
            reselectExternalappBotmaker({
              ...instance,
              [field]: value,
            })
          );
        } else {
          dispatch(
            reselectExternalappBotmaker({
              ...instance,
              fields: {
                ...instance.fields,
                [field]: value,
              },
            })
          );
        }
        break;
      case "groupchat-info":
        dispatch(
          reselectChat({
            chat: {
              ...instance,
              [field]: value,
            },
          })
        );
        break;
      case "vtex-info":
        if (
          value !== instance[field] &&
          ["name", "appkey", "apptoken", "accountid"].includes(field)
        ) {
          _changingValue.current = true;
        }
        if (instance && typeof value === "boolean" && field === "active") {
          dispatch(
            reselectExternalappVtex({
              ...instance,
              [field]: value,
            })
          );
        } else {
          dispatch(
            reselectExternalappVtex({
              ...instance,
              fields: {
                ...instance.fields,
                [field]: value,
              },
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const handleShowHide = (
    e: React.MouseEvent<Element, MouseEvent>,
    _type:
      | "agidesk-appkey"
      | "agibot-appkey"
      | "whatsappAppPhone"
      | "whatsAppBusinessAccountId"
      | "whatsAppKey"
      | "vtex-appkey"
  ) => {
    e.preventDefault();
    setShowHide((prevState) => ({
      "agidesk-appkey":
        _type === "agidesk-appkey"
          ? !prevState["agidesk-appkey"]
          : prevState["agidesk-appkey"],
      "agibot-appkey":
        _type === "agibot-appkey"
          ? !prevState["agibot-appkey"]
          : prevState["agibot-appkey"],
      whatsappAppPhone:
        _type === "whatsappAppPhone"
          ? !prevState.whatsappAppPhone
          : prevState.whatsappAppPhone,
      whatsAppBusinessAccountId:
        _type === "whatsAppBusinessAccountId"
          ? !prevState.whatsAppBusinessAccountId
          : prevState.whatsAppBusinessAccountId,
      whatsAppKey:
        _type === "whatsAppKey"
          ? !prevState.whatsAppKey
          : prevState.whatsAppKey,
      "vtex-appkey":
        _type === "vtex-appkey"
          ? !prevState["vtex-appkey"]
          : prevState["vtex-appkey"],
    }));
  };

  const getButton = () => {
    let _isValid = false;
    importTeamUsers.forEach((_team) => {
      if (_team.users && _team.users.length > 0) {
        _isValid = true;
      }
    });
    if (_isValid) {
      return (
        <Button
          minWidth={false}
          extraClass="mt-6"
          label="Avançar"
          icon="las la-arrow-right"
          onClick={() => {
            if (setShowModal) setShowModal(true);
          }}
        />
      );
    }
    return (
      <Button
        minWidth={false}
        extraClass="mt-6"
        label="Confirmar"
        icon="las la-save"
        onClick={async () => {
          const { payload } = await dispatch(
            agideskImport({
              data: importTeamUsers,
              externalappid: selectedExternalAppAgidesk?._id || "",
            })
          );
          if (payload !== null) {
            setImportTeamUsers([]);
          }
        }}
      />
    );
  };

  const getInstance = useCallback(() => {
    instance?.tags?.map((_tag: { _id: string; name: string }) =>
      optionsInstance.push({
        key: _tag._id,
        text: _tag.name,
        value: _tag._id,
      })
    );
    if (instance?.tags && instance?.tags.length > 0) {
      instance?.tags.forEach((_tag: ITag) => {
        if (typeof _tag?._id !== "undefined") {
          valueInstance.push(_tag._id);
        }
      });
    }
    const options = removeDuplicates(optionsInstance, "key");
    const defaultValue = removeDuplicates(valueInstance);
    return { options, defaultValue };
  }, []);

  useEffect(() => {
    if (type === "contact-info") {
      getInstance();
    }
  }, [getInstance]);

  useEffect(() => {
    if (
      type === "whatsapp-info" ||
      type === "agidesk-info" ||
      type === "agibot-info"
    ) {
      _changingValue.current = !instance?.fields?.validate;
    }
  }, [
    selectedExternalAppWhatsApp,
    selectedExternalAppAgidesk,
    selectedExternalAppBotmaker,
    selectedExternalAppVtex,
    instance,
  ]);

  useEffect(() => {
    if (type === "officehours") {
      const array: cellObject[] = [];
      JSON.stringify(
        {
          selectedFlatRows: selectedFlatRows.map((row) => {
            const original = row.original as cellObject;
            return array.push(original);
          }),
        },
        null,
        2
      );
      if (deletedItems && setSelectedRow) {
        deletedItems.current = array.map((item) => item._id);
        setSelectedRow(array);
      }
    }
  }, [selectedFlatRows]);

  const creationAndRelationButtons = ({
    onClickCreation,
    onClickRelation,
  }: {
    onClickCreation?: () => void;
    onClickRelation: () => void;
  }) => {
    if (
      type !== "settings-defaultteam" &&
      (instance?.deleted || !instance?.active)
    )
      return null;
    return (
      <div className="flex mt-6">
        {onClickCreation ? (
          <>
            <Button
              minWidth
              label="Criar"
              onClick={onClickCreation}
              icon="las la-plus"
              disabled={
                selectedExternalAppWhatsApp?.fields?.defaultteam &&
                selectedExternalAppWhatsApp?.fields?.defaultteam.length === 10
              }
            />
            <div className="mr-4" />
          </>
        ) : null}
        <Button
          minWidth
          label="Relacionar"
          onClick={onClickRelation}
          icon="las la-link"
          inverted
        />
      </div>
    );
  };

  const apptoken = selectedExternalAppBotmaker?._id;
  const webhookUrl = `${process.env.REACT_APP_API_BASE_URL}/api/webhook?apptoken=${apptoken}`;

  const loadAgideskTeams = async (_skip?: number, _limit?: number) => {
    const response = dispatch(
      getAgideskTeams({
        _teams: {
          skip: typeof _skip !== "undefined" ? _skip : skipTeamsPage,
          limit: typeof _limit !== "undefined" ? _limit : limitAgideskTeams,
          filter: "",
          externalappid: instance._id,
        },
      })
    );
    return response;
  };

  const handleCollapses = () => {
    const _teams: HTMLElement[] = document.querySelectorAll(
      ".la-angle-up"
    ) as unknown as HTMLElement[];
    _teams.forEach((collapse) => {
      collapse.click();
    });
  };

  const pageTeamsCount =
    typeof selectedExternalAppAgidesk?.totalAgideskTeams !== "undefined" &&
    selectedExternalAppAgidesk?.totalAgideskTeams > 0
      ? Math.ceil(
          selectedExternalAppAgidesk.totalAgideskTeams / limitAgideskTeams
        )
      : 0;

  const getAvailableApis = () => {
    const actionsList: any[] = [];

    if (
      typeof instance._id !== "undefined" &&
      typeof instance.fields !== "undefined" &&
      typeof instance.fields.apiurl !== "undefined" &&
      typeof instance.fields.integrations !== "undefined" &&
      instance.fields.integrations !== null &&
      typeof instance.fields.accountid !== "undefined"
    ) {
      Object.entries(instance.fields.integrations).forEach((service) => {
        const action = service[0];
        const { description, label, url } =
          service[1] as IAppPublicServicesItems;
        const informative =
          typeof description !== "undefined" && description.length > 0
            ? description.map((text) => {
                return text;
              })
            : "Utilize esta url para realizar consultas em aplicativos externos via integrações do Agitalks.";
        actionsList.push(
          <React.Fragment key={label}>
            <PopupComponent
              className="flex items-center mb-1"
              label={label}
              content={
                <h1 className="font-semibold text-[12px]">{informative}</h1>
              }
            />
            <Form.Input
              className="w-full md:w-full"
              // icon="linkify"
              // iconPosition="left"
              readOnly
              // label={label}
              value={url}
              fluid
              type="input"
              name={action}
              id={action}
              key={action}
              variant="outlined"
              placeholder={action}
              action
            >
              <input />
              <CopyButton _id={action} />
            </Form.Input>
          </React.Fragment>
        );
      });
    }
    return <Form>{actionsList}</Form>;
  };

  const getAvatarGroup = useCallback(() => {
    const includedInGroup =
      instance?.groupchat &&
      instance?.groupsettings
        ?.filter((_gs: ChatAdmin) => _gs?.active === true)
        ?.map((_u: ChatAdmin) => _u?.user?._id)
        .includes(user?._id);
    const userInGroup = instance?.groupsettings?.find(
      (_gs: ChatAdmin) => _gs?.user?._id === user?._id
    );
    if (!instance?.groupchat || (instance?.groupchat && includedInGroup))
      return instance;
    const _userInGroup = {
      avatar: {
        _id: userInGroup?.chatavatar?._id,
        path: userInGroup?.chatavatar?.path,
        name: userInGroup?.chatavatar?.name,
      },
      name: userInGroup?.chatname,
    };
    return _userInGroup;
  }, [instance]);

  const sectionElements = {
    "info-users": (
      <>
        <FormAvatar
          instance={instance}
          onRemove={
            instance?.deleted ||
            selectedChat?.type === ChatType.INTERNAL ||
            (selectedHistoryContact !== null &&
              typeof selectedHistoryContact?.key !== "undefined" &&
              selectedHistoryContact?.key.length > 0)
              ? // (!isAdmin &&
                //   !location.pathname.includes("profile") &&
                //   !location.pathname.includes("contact"))
                undefined
              : handleDeleteAvatar
          }
          onChange={
            instance?.deleted ||
            selectedChat?.type === ChatType.INTERNAL ||
            (selectedHistoryContact !== null &&
              typeof selectedHistoryContact?.key !== "undefined" &&
              selectedHistoryContact?.key.length > 0)
              ? // (!isAdmin &&
                //   !location.pathname.includes("profile") &&
                //   !location.pathname.includes("contact"))
                undefined
              : handleAvatarChange
          }
        />
        <Form className="mt-4">
          {location.hash.includes("#infos") ? (
            <>
              <Form.Input
                autoComplete="off"
                readOnly={
                  instance?.deleted ||
                  selectedChat?.type === ChatType.INTERNAL ||
                  (selectedHistoryContact !== null &&
                    typeof selectedHistoryContact?.key !== "undefined" &&
                    selectedHistoryContact?.key.length > 0)
                  // (!isAdmin &&
                  //   !location.pathname.includes("profile") &&
                  //   !location.pathname.includes("contact"))
                }
                className="w-full md:w-full"
                label="Nome"
                fluid
                placeholder="Nome"
                defaultValue={instance?.name}
                type="text"
                required
                onChange={(e, { value }) => {
                  handleOnChangeInfo({
                    field: "name",
                    value,
                  });
                }}
              />
              <Form.Input
                autoComplete="new-email"
                className="w-full md:w-full"
                label="E-mail"
                placeholder="E-mail"
                required
                readOnly={
                  instance?.roles?.includes(RoleUser.AGENT) ||
                  instance?.roles?.includes(RoleUser.ADMINISTRATOR) ||
                  (selectedHistoryContact !== null &&
                    typeof selectedHistoryContact?.key !== "undefined" &&
                    selectedHistoryContact?.key.length > 0)
                }
                value={instance?.email?.trim()?.toLowerCase()}
                onChange={(e) => {
                  if (instance?.roles?.includes(RoleUser.CONTACT)) {
                    handleOnChangeInfo({
                      field: "email",
                      value: e.target.value?.trim()?.toLowerCase(),
                    });
                  }
                }}
                type="email"
              />
              <PhoneFlagInput
                className="w-full md:w-full"
                key="phone"
                required={type === "contact-info"}
                readOnly={
                  instance?.deleted ||
                  selectedChat?.type === ChatType.INTERNAL ||
                  (selectedHistoryContact !== null &&
                    typeof selectedHistoryContact?.key !== "undefined" &&
                    selectedHistoryContact?.key.length > 0)
                  // (!isAdmin &&
                  //   !location.pathname.includes("profile") &&
                  //   !location.pathname.includes("contact"))
                }
                defaultValue={instance?.phone}
                onChange={(value: string) => {
                  handleOnChangeInfo({
                    field: "phone",
                    value,
                  });
                }}
              />
              {/* <PhoneMaskInput
                className="w-full md:w-full"
                key="phone"
                required={type === "contact-info"}
                readOnly={
                  instance?.deleted ||
                  selectedChat?.type === ChatType.INTERNAL ||
                  (selectedHistoryContact !== null &&
                    typeof selectedHistoryContact?.key !== "undefined" &&
                    selectedHistoryContact?.key.length > 0)
                  // (!isAdmin &&
                  //   !location.pathname.includes("profile") &&
                  //   !location.pathname.includes("contact"))
                }
                defaultValue={instance?.phone}
                onChange={(e) => {
                  handleOnChangeInfo({
                    field: "phone",
                    value: e.target.value,
                  });
                }}
              /> */}
              {type === "contact-info" && showCode ? (
                <Form.Group widths="equal">
                  <Form.Input
                    className="w-full md:w-full"
                    readOnly={
                      instance?.deleted ||
                      selectedChat?.type === ChatType.INTERNAL ||
                      (selectedHistoryContact !== null &&
                        typeof selectedHistoryContact?.key !== "undefined" &&
                        selectedHistoryContact?.key.length > 0)
                      // (!isAdmin &&
                      //   !location.pathname.includes("profile") &&
                      //   !location.pathname.includes("contact"))
                    }
                    label="CPF"
                    children={
                      <ReactInputMask
                        min={11}
                        max={11}
                        mask="999.999.999-99"
                        readOnly={
                          instance?.deleted ||
                          selectedChat?.type === ChatType.INTERNAL ||
                          (selectedHistoryContact !== null &&
                            typeof selectedHistoryContact?.key !==
                              "undefined" &&
                            selectedHistoryContact?.key.length > 0)
                          // (!isAdmin &&
                          //   !location.pathname.includes("profile") &&
                          //   !location.pathname.includes("contact"))
                        }
                        placeholder="CPF"
                        defaultValue={instance?.code}
                        onChange={(e) => {
                          handleOnChangeInfo({
                            field: "code",
                            value: e.target.value.replace(/\D+/g, ""),
                          });
                        }}
                        maskPlaceholder={null}
                      />
                    }
                    autoComplete="off"
                  />
                </Form.Group>
              ) : null}
              {type === "contact-info" ? (
                <Form.Group widths="equal">
                  <ContactTagsDropdown
                    allowAdditions={false}
                    defaultValue={
                      valueInstance.length === 0
                        ? getInstance().defaultValue
                        : valueInstance
                    }
                    disabled={
                      instance?.deleted ||
                      (selectedHistoryContact !== null &&
                        typeof selectedHistoryContact?.key !== "undefined" &&
                        selectedHistoryContact?.key.length > 0)
                    }
                    focusId={focusId}
                    setFocusId={setFocusId}
                    isLoadingDropdown={isLoadingDropdown}
                    setIsLoadingDropdown={setIsLoadingDropdown}
                    options={
                      optionsInstance.length === 0
                        ? getInstance().options
                        : optionsInstance
                    }
                    setOptions={setOptionsInstance}
                    handleOnChangeInfo={handleOnChangeInfo}
                  />
                </Form.Group>
              ) : null}
            </>
          ) : (
            <>
              <Form.Group widths="equal">
                <Form.Input
                  autoComplete="off"
                  readOnly={
                    instance?.deleted ||
                    (!isAdmin &&
                      !location.pathname.includes("profile") &&
                      !location.pathname.includes("contact"))
                  }
                  className="w-full md:w-full"
                  label="Nome"
                  // width={12}
                  fluid
                  placeholder="Nome"
                  defaultValue={instance?.name}
                  type="text"
                  required
                  onChange={(e, { value }) => {
                    handleOnChangeInfo({
                      field: "name",
                      value,
                    });
                  }}
                />
                <ActiveDropdown
                  disabled={
                    instance?.deleted ||
                    location.pathname.includes("profile") ||
                    (!isAdmin && !location.pathname.includes("contact"))
                  }
                  // width={4}
                  className="w-full mt-6"
                  fluid
                  defaultValue={instance?.active || false}
                  onChange={(e, { value }) => {
                    if (typeof value === "boolean")
                      handleOnChangeInfo({
                        field: "active",
                        value,
                      });
                  }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  // width={12}
                  autoComplete="new-email"
                  className="w-full md:w-full"
                  label="E-mail"
                  placeholder="E-mail"
                  required
                  readOnly={
                    instance?.roles?.includes(RoleUser.AGENT) ||
                    instance?.roles?.includes(RoleUser.ADMINISTRATOR)
                  }
                  value={instance?.email?.trim()?.toLowerCase()}
                  type="email"
                  onChange={(e) => {
                    if (instance?.roles?.includes(RoleUser.CONTACT)) {
                      handleOnChangeInfo({
                        field: "email",
                        value: e.target.value?.trim()?.toLowerCase(),
                      });
                    }
                  }}
                />
                {/* <PhoneMaskInput
                  // width={4}
                  className="w-full md:w-full"
                  key="phone"
                  readOnly={
                    instance?.deleted ||
                    (!isAdmin &&
                      !location.pathname.includes("profile") &&
                      !location.pathname.includes("contact"))
                  }
                  required={type === "contact-info"}
                  defaultValue={instance?.phone}
                  onChange={(e) => {
                    handleOnChangeInfo({
                      field: "phone",
                      value: e.target.value,
                    });
                  }}
                /> */}
                <PhoneFlagInput
                  className="w-full md:w-full"
                  key="phone"
                  readOnly={
                    instance?.deleted ||
                    (!isAdmin &&
                      !location.pathname.includes("profile") &&
                      !location.pathname.includes("contact"))
                  }
                  required={type === "contact-info"}
                  defaultValue={instance?.phone}
                  onChange={(value: string) => {
                    handleOnChangeInfo({
                      field: "phone",
                      value,
                    });
                  }}
                />
              </Form.Group>
              {type === "user-info" &&
              isAdmin &&
              !location.pathname.includes("profile") &&
              !location.pathname.includes("contact") ? (
                <Form.Group widths="equal">
                  <RolesDropdown
                    disabled={
                      instance?.deleted ||
                      (!isAdmin &&
                        !location.pathname.includes("profile") &&
                        !location.pathname.includes("contact"))
                    }
                    error={instance?.roles?.length === 0}
                    defaultValue={instance?.roles}
                    onChange={(e, { value }) => {
                      const _roles = value as RoleUser[];
                      if (typeof _roles !== "undefined") {
                        if (!_roles?.includes(RoleUser.AGENT)) {
                          _roles?.unshift(RoleUser.AGENT);
                        }
                        handleOnChangeInfo({
                          field: "roles",
                          value: _roles,
                        });
                      }
                    }}
                  />
                </Form.Group>
              ) : null}
              {type === "contact-info" ? (
                <Form.Group widths="equal">
                  <ContactTagsDropdown
                    allowAdditions
                    defaultValue={valueInstance}
                    disabled={instance?.deleted}
                    focusId={focusId}
                    setFocusId={setFocusId}
                    isLoadingDropdown={isLoadingDropdown}
                    setIsLoadingDropdown={setIsLoadingDropdown}
                    options={optionsInstance}
                    setOptions={setOptionsInstance}
                    handleOnChangeInfo={handleOnChangeInfo}
                  />
                </Form.Group>
              ) : null}
            </>
          )}
        </Form>
        {type.includes("profile") ? (
          <div className="flex mt-4">
            {widget ? (
              <Button
                minWidth={false}
                label="Voltar"
                onClick={() => {
                  navigate("/chat");
                }}
                icon="las la-arrow-left"
                inverted
              />
            ) : null}
            {widget ? <div className="mr-2" /> : null}
            <Button
              minWidth
              label="Alterar senha"
              onClick={() => {
                if (setVisible) {
                  setVisible(true);
                }
              }}
              icon="las la-key"
              inverted
            />
            {widget ? <div className="mr-2" /> : null}
            {widget ? (
              <Button
                minWidth
                label="Salvar"
                onClick={async () => {
                  const _updateUser = {
                    _id: instance?._id,
                    name: instance?.name,
                    active: instance?.active,
                    phone: instance?.phone,
                  };
                  const { payload } = await dispatch(update({ _updateUser }));
                  const _payload = payload as IUser;
                  dispatch(
                    reselectUser({
                      ...instance,
                      updated_at: _payload.updated_at,
                      updated_by: _payload.updated_by,
                    })
                  );
                }}
                icon="las la-save"
              />
            ) : null}
          </div>
        ) : null}
      </>
    ),
    "info-groupchat": (
      <>
        <FormAvatar
          instance={getAvatarGroup()}
          onRemove={
            !instance?.groupsettings
              ?.filter(
                (_groupuser: ChatAdmin) => _groupuser.user?._id === user?._id
              )[0]
              ?.role?.includes(ChatGroupRole.ADMIN) || instance?.deleted
              ? undefined
              : handleDeleteAvatar
          }
          onChange={
            !instance?.groupsettings
              ?.filter(
                (_groupuser: ChatAdmin) => _groupuser.user?._id === user?._id
              )[0]
              ?.role?.includes(ChatGroupRole.ADMIN) || instance?.deleted
              ? undefined
              : handleAvatarChange
          }
        />
        <Form className="pt-1">
          <PopupComponent
            className="flex items-center mb-1"
            label="Nome"
            required
            content={
              <h1 className="font-semibold text-[12px]">
                Máximo 30 caracteres
              </h1>
            }
          />
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full"
            // icon="users"
            // iconPosition="left"
            fluid
            readOnly={
              !instance?.groupsettings
                ?.filter(
                  (_groupuser: ChatAdmin) => _groupuser.user?._id === user?._id
                )[0]
                ?.role?.includes(ChatGroupRole.ADMIN)
            }
            value={chatName}
            required
            // minLength={2}
            maxLength={30}
            onChange={(e, { value }) => {
              if (value.length <= 30 && setChatName) {
                setChatName(value);
              }
            }}
            onBlur={nameBlurHandler}
            error={instance?.name?.length < 2}
            type="text"
            name="name"
            variant="outlined"
            placeholder="Nome"
          />
        </Form>
      </>
    ),
    "info-teams": (
      <Form>
        <Form.Group widths="equal">
          <div className="w-full ml-2">
            <PopupComponent
              className="flex items-center mb-1"
              label="Nome"
              required
              content={
                <h1 className="font-semibold text-[12px]">
                  Máximo 24 caracteres
                </h1>
              }
            />
            <Form.Input
              autoComplete="off"
              className="w-full md:w-full"
              // icon="users"
              // iconPosition="left"
              fluid
              readOnly={
                instance?.deleted ||
                (!isAdmin && !location.pathname.includes("customer"))
              }
              defaultValue={instance?.name}
              required
              // minLength={2}
              maxLength={24}
              onChange={(e, { value }) => {
                if (value.length <= 24) {
                  handleOnChangeInfo({
                    field: "name",
                    value,
                  });
                }
              }}
              onBlur={nameBlurHandler}
              error={instance?.name?.length < 2}
              type="text"
              name="name"
              variant="outlined"
              // size="small"
              placeholder="Nome completo"
            />
          </div>
          <ActiveDropdown
            disabled={
              instance?.deleted ||
              (!isAdmin && !location.pathname.includes("customer"))
            }
            className="mt-6"
            fluid
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>
        {/* <Form.Group> */}
        <Dropdown
          id="dropdown-team-officehour"
          gotoTitle={
            selectedTeam?.officehour?._id
              ? `Ir para ${selectedTeam?.officehour?.name}`
              : undefined
          }
          disabled={instance?.deleted || !isAdmin}
          gotoClick={
            selectedTeam?.officehour?._id && isAdmin
              ? () =>
                  navigate(
                    `/settings/officehour/${selectedTeam?.officehour?._id}`
                  )
              : undefined
          }
          loading={isLoadingDropdown && focusId === "dropdown-team-officehour"}
          className="w-full"
          label="Horário de atendimento"
          fluid
          search
          selection
          defaultValue={instance?.officehour?._id}
          options={
            // eslint-disable-next-line no-nested-ternary
            optionsInstance.length > 0
              ? optionsInstance
              : typeof selectedTeam?.officehour?._id !== "undefined"
              ? [
                  {
                    key: 0,
                    text: instance?.officehour?.name,
                    value: instance?.officehour?._id,
                  },
                ]
              : []
          }
          onSearchChange={async (e: DropdownSearchInputProps) => {
            setIsLoadingDropdown(true);
            const payload = await officehoursService.search({
              skip: 0,
              limit: 10,
              filter: e.target.value,
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsInstance: ddOptions[] = optionsInstance;
            payload.results.forEach((_officehour: IOfficehour) => {
              _optionsInstance.push({
                key: _officehour._id,
                text: _officehour.name,
                value: _officehour._id,
              });
            });
            setOptionsInstance(_optionsInstance);
          }}
          onOpen={async () => {
            setIsLoadingDropdown(true);
            const payload = await officehoursService.search({
              skip: 0,
              limit: 10,
              filter: "",
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsInstance: object[] = [];
            payload.results.forEach((_officehour: IOfficehour) => {
              _optionsInstance.push({
                key: _officehour._id,
                text: _officehour.name,
                value: _officehour._id,
              });
            });
            setOptionsInstance(_optionsInstance);
          }}
          onFocus={() => {
            setFocusId("dropdown-team-officehour");
          }}
          onBlur={() => {
            setFocusId("");
          }}
          placeholder="Horário de atendimento"
          onChange={async (e, { value }) => {
            const target = e.target as HTMLInputElement;
            const _value: { _id: string; name: string } = {
              _id: value as string,
              name: target.innerText,
            };
            handleOnChangeInfo({
              field: "officehour",
              value: _value,
            });
          }}
        />
        <Dropdown
          id="dropdown-team-survey"
          gotoTitle={
            selectedTeam?.survey?._id
              ? `Ir para ${selectedTeam?.survey?.name}`
              : undefined
          }
          disabled={instance?.deleted || !isAdmin}
          gotoClick={
            selectedTeam?.survey?._id && isAdmin
              ? () => navigate(`/settings/survey/${selectedTeam?.survey?._id}`)
              : undefined
          }
          loading={isLoadingDropdown && focusId === "dropdown-team-survey"}
          className="w-full"
          label="Pesquisa de satisfação"
          fluid
          search
          selection
          defaultValue={instance?.survey?._id}
          options={
            // eslint-disable-next-line no-nested-ternary
            optionsInstance.length > 0
              ? optionsInstance
              : typeof selectedTeam?.survey?._id !== "undefined"
              ? [
                  {
                    key: 0,
                    text: instance?.survey?.name,
                    value: instance?.survey?._id,
                  },
                ]
              : []
          }
          onSearchChange={async (e: DropdownSearchInputProps) => {
            setIsLoadingDropdown(true);
            const payload = await surveysService.search({
              skip: 0,
              limit: 10,
              filter: e.target.value,
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsInstance: ddOptions[] = optionsInstance;
            payload.results.forEach((_survey: ISurvey) => {
              _optionsInstance.push({
                key: _survey._id,
                text: _survey.name,
                value: _survey._id,
              });
            });
            setOptionsInstance(_optionsInstance);
          }}
          onOpen={async () => {
            setIsLoadingDropdown(true);
            const payload = await surveysService.search({
              skip: 0,
              limit: 10,
              filter: "",
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsInstance: object[] = [];
            payload.results.forEach((_survey: ISurvey) => {
              _optionsInstance.push({
                key: _survey._id,
                text: _survey.name,
                value: _survey._id,
              });
            });
            setOptionsInstance(_optionsInstance);
          }}
          onFocus={() => {
            setFocusId("dropdown-team-survey");
          }}
          onBlur={() => {
            setFocusId("");
          }}
          placeholder="Pesquisa de satisfação"
          onChange={async (e, { value }) => {
            if (
              typeof value !== "undefined" &&
              value &&
              value.toString().length > 0
            ) {
              const target = e.target as HTMLInputElement;
              const _value: { _id: string; name: string } = {
                _id: value as string,
                name: target.innerText,
              };
              handleOnChangeInfo({
                field: "survey",
                value: _value,
              });
            }
          }}
        />
        {/* </Form.Group> */}
      </Form>
    ),
    "info-customers": (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full"
            // icon="building"
            // iconPosition="left"
            label="Nome"
            fluid
            readOnly={
              instance?.deleted ||
              (!isAdmin && !location.pathname.includes("customer"))
            }
            defaultValue={instance?.name}
            required
            // minLength={2}
            // maxLength={type === "team-info" ? 24 : undefined}
            onChange={(e, { value }) => {
              // if (value.length <= 24 || type !== "team-info") {
              handleOnChangeInfo({
                field: "name",
                value,
              });
              // }
            }}
            onBlur={nameBlurHandler}
            error={instance?.name?.length < 2}
            type="text"
            name="name"
            variant="outlined"
            // size="small"
            placeholder="Nome completo"
          />
          <ActiveDropdown
            disabled={
              instance?.deleted ||
              (!isAdmin && !location.pathname.includes("customer"))
            }
            className="w-full mt-6"
            fluid
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>
      </Form>
    ),
    "info-holiday": (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full"
            // icon="calendar outline"
            // iconPosition="left"
            label="Nome"
            // width={12}
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.name}
            required
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "name",
                value,
              });
            }}
            onBlur={nameBlurHandler}
            error={instance?.name?.length < 2}
            type="text"
            name="name"
            variant="outlined"
            // size="small"
            placeholder="Nome"
          />
          <ActiveDropdown
            // width={4}
            disabled={instance?.deleted || !isAdmin}
            className="w-full mt-6"
            fluid
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <DaysDropdown
            // width={4}
            disabled={instance?.deleted || !isAdmin}
            fluid
            required
            defaultValue={instance?.day}
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "day",
                value,
              });
            }}
          />
          <MonthsDropdown
            // width={8}
            fluid
            required
            defaultValue={instance?.month}
            disabled={instance?.deleted || !isAdmin}
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "month",
                value,
              });
            }}
          />
          <YearsDropdown
            // width={4}
            fluid
            required
            defaultValue={instance?.year}
            disabled={instance?.deleted || !isAdmin}
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "year",
                value,
              });
            }}
          />
        </Form.Group>
      </Form>
    ),
    "info-offlinemessage": (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full"
            // icon="calendar outline"
            // iconPosition="left"
            label="Nome"
            // width={12}
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.name}
            required
            onChange={(e, { value }) => {
              if (setSelected) {
                setSelected((prevState: any) => {
                  return {
                    ...prevState,
                    name: value,
                  };
                });
              }
            }}
            onBlur={nameBlurHandler}
            error={instance?.name?.length < 2}
            type="text"
            name="name"
            variant="outlined"
            // size="small"
            placeholder="Nome"
          />
          <ActiveDropdown
            // width={4}
            disabled={instance?.deleted || !isAdmin}
            className="w-full mt-6"
            fluid
            defaultValue={selectedOfflinemessage?.active || false}
            onChange={(e, { value }) => {
              if (setSelected) {
                setSelected((prevState: any) => {
                  return {
                    ...prevState,
                    active: value as boolean,
                  };
                });
              }
              // handleOnChangeInfo({
              //   field: "active",
              //   value,
              // });
            }}
          />
        </Form.Group>
      </Form>
    ),
    "email-offlinemessage": (
      <Form>
        <Form.Group>
          <div className="w-full">
            <PopupComponent
              className="flex items-center mb-1"
              label="E-mails"
              required={instance?.widget?.active}
              content={
                <h1 className="font-semibold text-[12px]">
                  Apenas e-mails válidos e separados por ponto-e-vírgula ou
                  enter
                </h1>
              }
            />
            <Form.Input
              className="w-full md:w-full"
              readOnly={instance?.deleted || !isAdmin}
              error={
                instance?.email?.length === 0 &&
                instance?.widget?.active &&
                focusId === "email-offlinemessage"
              }
              // label="E-mails"
              children={
                <div className="w-full">
                  <ReactMultiEmail
                    placeholder="E-mails"
                    style={{
                      borderColor:
                        instance?.email?.length === 0 &&
                        instance?.widget?.active &&
                        focusId === "email-offlinemessage"
                          ? "#EF4444" // "#DB2828"
                          : undefined,
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            role="presentation"
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                    autoComplete="off"
                    delimiter=";"
                    emails={instance?.email?.split(";") || []}
                    onChangeInput={(value) => {
                      if (!value && instance?.email?.length === 0) {
                        setFocusId("email-offlinemessage");
                      }
                    }}
                    onChange={(_emails: string[]) => {
                      if (_emails.length === 0) {
                        setFocusId("email-offlinemessage");
                      } else {
                        setFocusId("");
                      }
                      if (setSelected) {
                        setSelected((prevState: any) => ({
                          ...prevState,
                          email: _emails?.join(";")?.toLowerCase(),
                          // widget:
                          //   _emails?.length === 0
                          //     ? {
                          //         active: false,
                          //         content: "",
                          //       }
                          //     : prevState?.widget,
                        }));
                      }
                    }}
                  />
                  {instance?.email?.length === 0 && instance?.widget?.active ? (
                    <div className="mt-4">
                      <ErrorLabel error label="Campo obrigatório" />
                    </div>
                  ) : null}
                </div>
              }
              autoComplete="off"
            />
          </div>
        </Form.Group>
      </Form>
    ),
    "channels-offlinemessage": (
      <Form id="channels-offlinemessage">
        <label className="mb-6" htmlFor="channels-offlinemessage">
          Permitir atendimento fora do horário
        </label>
        <div className={`${!instance?.widget?.active ? "mb-4" : "mb-6"}`}>
          <Toggle
            containerClass="flex w-full"
            checked={instance?.whatsapp?.active}
            readOnly={instance?.deleted || !isAdmin}
            mainClass="items-center flex"
            onChange={(_, { checked }) => {
              if (checked) {
                navigate(`${location.pathname}#whatsapp-channel`);
              } else {
                navigate(location.pathname);
              }
              if (setSelected) {
                setSelected((prevState: any) => {
                  return {
                    ...prevState,
                    whatsapp: {
                      active: checked || false,
                      content: "",
                    },
                  };
                });
              }
            }}
            labelClass="ml-4 mb-4"
            label="WhatsApp"
          />
          {instance?.whatsapp?.active ? (
            <>
              <Form.TextArea
                className="w-full"
                autoComplete="off"
                required={instance?.whatsapp?.active}
                error={
                  instance?.whatsapp?.active &&
                  instance?.whatsapp?.content?.length < 2
                }
                readOnly={instance?.deleted || !isAdmin}
                label="Mensagem para WhatsApp"
                placeholder="Mensagem para WhatsApp"
                type="text"
                defaultValue={instance?.whatsapp?.content}
                onChange={(e, { value }) => {
                  if (setSelected) {
                    setSelected((prevState: any) => {
                      return {
                        ...prevState,
                        whatsapp: {
                          ...prevState?.whatsapp,
                          content: value,
                        },
                      };
                    });
                  }
                }}
              />
              {instance?.whatsapp.active &&
              instance?.whatsapp.content.length < 2 ? (
                <div className="mt-4">
                  <ErrorLabel error label="Campo obrigatório" />
                </div>
              ) : null}
              <LinkButton
                extraClass="mb-4"
                disabled={instance?.agibot.content.length < 2}
                label="Habilitar perfil de atendimento"
                onClick={() => navigate("/settings/integration/whatsapp")}
              />
            </>
          ) : null}
        </div>
        <div className={`${!instance?.widget?.active ? "mb-4" : "mb-6"}`}>
          <Toggle
            containerClass="flex w-full"
            checked={instance?.agibot?.active}
            readOnly={instance?.deleted || !isAdmin}
            mainClass="items-center flex"
            onChange={(_, { checked }) => {
              if (checked) {
                navigate(`${location.pathname}#agibot-channel`);
              } else {
                navigate(location.pathname);
              }
              if (setSelected) {
                setSelected((prevState: any) => {
                  return {
                    ...prevState,
                    agibot: {
                      active: checked || false,
                      content: "",
                    },
                  };
                });
              }
            }}
            labelClass="ml-4 mb-4"
            label="Agibot"
          />
          {instance?.agibot?.active ? (
            <>
              <Form.TextArea
                className="w-full"
                autoComplete="off"
                required={instance?.agibot?.active}
                error={
                  instance?.agibot?.active &&
                  instance?.agibot?.content?.length < 2
                }
                readOnly={instance?.deleted || !isAdmin}
                label="Mensagem para Agibot"
                placeholder="Mensagem para Agibot"
                type="text"
                defaultValue={instance?.agibot?.content}
                onChange={(e, { value }) => {
                  if (setSelected) {
                    setSelected((prevState: any) => {
                      return {
                        ...prevState,
                        agibot: {
                          ...prevState?.agibot,
                          content: value,
                        },
                      };
                    });
                  }
                }}
              />
              {instance?.agibot.active &&
              instance?.agibot.content.length < 2 ? (
                <div className="mt-4">
                  <ErrorLabel error label="Campo obrigatório" />
                </div>
              ) : null}
              <LinkButton
                extraClass="mb-4"
                disabled={instance?.agibot.content.length < 2}
                label="Habilitar perfil de atendimento"
                onClick={() => navigate("/settings/integration/agibot")}
              />
            </>
          ) : null}
        </div>
        <div>
          <Toggle
            containerClass="flex w-full"
            checked={instance?.widget?.active}
            readOnly={instance?.deleted || !isAdmin}
            mainClass="items-center flex"
            onChange={(_, { checked }) => {
              if (checked) {
                navigate(`${location.pathname}#widget-channel`);
              } else {
                navigate(location.pathname);
              }
              if (setSelected) {
                setSelected((prevState: any) => {
                  return {
                    ...prevState,
                    widget: {
                      active: checked || false,
                      content: "",
                    },
                  };
                });
              }
            }}
            labelClass="ml-4 mb-4"
            label="Widget"
          />
          {instance?.widget?.active ? (
            <>
              <Form.TextArea
                className="w-full"
                autoComplete="off"
                error={
                  instance?.widget?.active &&
                  instance?.widget?.content?.length < 2
                }
                required={instance?.widget?.active}
                readOnly={instance?.deleted || !isAdmin}
                label="Mensagem para o widget"
                placeholder="Mensagem para o widget"
                type="text"
                defaultValue={instance?.widget?.content}
                onChange={(e, { value }) => {
                  if (setSelected) {
                    setSelected((prevState: any) => {
                      return {
                        ...prevState,
                        widget: {
                          ...prevState?.widget,
                          content: value,
                        },
                      };
                    });
                  }
                }}
              />
              {instance?.widget?.active &&
              instance?.widget.content.length < 2 ? (
                <div className="mt-4">
                  <ErrorLabel error label="Campo obrigatório" />
                </div>
              ) : null}
              <LinkButton
                extraClass="mb-4"
                disabled={
                  instance?.email?.length === 0 &&
                  instance?.widget?.active &&
                  instance?.widget.content.length < 2
                }
                label="Habilitar perfil de atendimento"
                onClick={() => navigate("/settings/general")}
              />
            </>
          ) : null}
        </div>
      </Form>
    ),
    "info-officehour": (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full"
            // icon="calendar outline"
            // iconPosition="left"
            label="Nome"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.name}
            required
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "name",
                value,
              });
            }}
            onBlur={nameBlurHandler}
            error={instance?.name?.length < 2}
            type="text"
            name="name"
            variant="outlined"
            // size="small"
            placeholder="Nome completo"
          />
          <ActiveDropdown
            disabled={
              selectedOfficehour?.deleted ||
              !isAdmin ||
              selectedOfficehour?.default
            }
            className="w-full mt-6"
            fluid
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>
        {!instance?.default ? (
          <Toggle
            containerClass="flex w-full mt-6"
            defaultChecked={instance?.default || false}
            readOnly={instance?.deleted || !instance?.active || !isAdmin}
            mainClass="items-center flex"
            onChange={async (_, { checked }) => {
              const _officehour = {
                ...selectedOfficehour,
                _id: selectedOfficehour?._id,
                name: selectedOfficehour?.name,
                active: selectedOfficehour?.active,
                teams: selectedOfficehour?.teams?.map((_t: ITeam) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
                enablelink: selectedOfficehour?.enablelink,
                officehours: selectedOfficehour?.officehours,
                default: checked,
              };
              const { payload } = await dispatch(
                updateOfficehour({ _officehour })
              );
              dispatch(
                reselectOfficehour({
                  ...selectedOfficehour,
                  updated_at: payload.updated_at,
                  updated_by: payload.updated_by,
                })
              );
              handleOnChangeInfo({
                field: "default",
                value: checked || false,
              });
            }}
            labelClass="ml-4 mb-3"
            label="Definir como horário padrão"
          />
        ) : (
          <PopupComponent
            className="mt-6 flex items-center w-full"
            label="Horário definido como padrão do sistema"
            italic
            onClick={() => navigate("/settings/general#settings-officehour")}
            content={
              <h1>
                Equipes sem horário de atendimento definido respeitarão este
                horário.
              </h1>
            }
          />
        )}
      </Form>
    ),
    "agidesk-login": (
      <>
        <Toggle
          containerClass={`flex w-full ${
            instance?.fields?.enablelogin ? "mb-4" : ""
          } `}
          defaultChecked={instance?.fields?.enablelogin || false}
          readOnly={instance?.deleted || !isAdmin}
          mainClass="items-center flex"
          onChange={(_, { checked }) => {
            if (checked) {
              navigate(`${location.pathname}#agidesk-login`);
            } else {
              navigate(location.pathname);
            }
            handleOnChangeInfo({
              field: "enablelogin",
              value: checked || false,
            });
          }}
          labelClass="ml-4"
          label="Permitir login com o Agidesk"
        />
        {instance?.fields?.enablelogin ? (
          <Form>
            <Form.Input
              autoComplete="off"
              readOnly={instance?.deleted || !isAdmin}
              label="Título do login"
              placeholder="Título do login"
              type="text"
              defaultValue={instance?.fields?.displaytitlelogin || ""}
              onChange={(e, { value }) => {
                handleOnChangeInfo({
                  field: "displaytitlelogin",
                  value,
                });
              }}
            />
          </Form>
        ) : null}
      </>
    ),
    "agidesk-ticket": (
      <>
        <Toggle
          containerClass={`flex w-full ${
            instance?.fields?.enablechat ? "mb-4" : ""
          } `}
          defaultChecked={instance?.fields?.enablechat || false}
          readOnly={instance?.deleted || !isAdmin}
          mainClass="items-center flex"
          onChange={(_, { checked }) => {
            if (checked) {
              navigate(`${location.pathname}#agidesk-ticket`);
            } else {
              navigate(location.pathname);
            }
            handleOnChangeInfo({
              field: "enablechat",
              value: checked || false,
            });
          }}
          labelClass="ml-4"
          label="Exibir no menu da conversa"
        />
        {instance?.fields?.enablechat ? (
          <Form>
            <Form.Input
              autoComplete="off"
              readOnly={instance?.deleted || !isAdmin}
              label="Título da opção"
              placeholder="Título da opção"
              type="text"
              defaultValue={instance?.fields?.displaytitlechat || ""}
              onChange={(e, { value }) => {
                handleOnChangeInfo({
                  field: "displaytitlechat",
                  value,
                });
              }}
            />
            <ServicerestrictDropdown
              disabled={instance?.deleted || !isAdmin}
              className="mt-6 w-full"
              fluid
              defaultValue={instance?.fields?.servicerestrict || false}
              onChange={(e, { value }) => {
                if (typeof value === "boolean")
                  handleOnChangeInfo({
                    field: "servicerestrict",
                    value,
                  });
              }}
            />
            {instance?.fields?.servicerestrict ? (
              <CategoriesTopicsSelectionComponent type="agidesk-ticket-create" />
            ) : null}
            <Form.Input
              autoComplete="off"
              readOnly={instance?.deleted || !isAdmin}
              label="Assunto"
              placeholder="Assunto"
              type="text"
              defaultValue={instance?.fields?.ticketinfo?.title || ""}
              onChange={(e, { value }) => {
                handleOnChangeInfo({
                  field: "title",
                  value,
                });
              }}
            />
            <Form.TextArea
              readOnly={instance?.deleted || !isAdmin}
              style={{ resize: "none" }}
              label="Como podemos ajudar?"
              placeholder="Descrição"
              maxLength={1024}
              type="text"
              defaultValue={instance?.fields?.ticketinfo?.subject || ""}
              onChange={(e, { value }) => {
                handleOnChangeInfo({
                  field: "subject",
                  value,
                });
              }}
            />
            <Form.Group className="!flex !flex-col">
              <div className="sm:flex sm:!flex-row flex-col">
                <Toggle
                  containerClass="flex w-full ml-2 my-2"
                  defaultChecked={
                    instance?.fields?.ticketinfo?.includechat || false
                  }
                  readOnly={instance?.deleted || !isAdmin}
                  mainClass=""
                  onChange={(_, { checked }) => {
                    handleOnChangeInfo({
                      field: "includechat",
                      value: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-1.5"
                  label="Incluir conversa ao atendimento"
                  simple
                />
                <Toggle
                  containerClass="flex w-full ml-2 my-2"
                  defaultChecked={
                    instance?.fields?.ticketinfo?.internal || false
                  }
                  readOnly={instance?.deleted || !isAdmin}
                  mainClass=""
                  onChange={(_, { checked }) => {
                    handleOnChangeInfo({
                      field: "internal",
                      value: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-1.5"
                  label="Interno (não notificar o cliente)"
                  simple
                />
              </div>
              <div className="sm:flex sm:!flex-row flex-col mb-6">
                <Toggle
                  containerClass="flex w-full ml-2 my-2"
                  defaultChecked={
                    instance?.fields?.ticketinfo?.openingsolved || false
                  }
                  readOnly={instance?.deleted || !isAdmin}
                  mainClass=""
                  onChange={(_, { checked }) => {
                    handleOnChangeInfo({
                      field: "openingsolved",
                      value: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-1.5"
                  label="Marcar como resolvido"
                  simple
                />
              </div>
            </Form.Group>
          </Form>
        ) : null}
      </>
    ),
    // TO DO
    "agidesk-chat-finish": (
      <AgideskChatFinish
        selectedInstance={instance}
        instanceDispatch={reselectExternalappAgidesk}
        isAdmin={isAdmin}
      />
    ),
    "agidesk-import": (
      <>
        <Toggle
          checked={showImport}
          containerClass={`flex w-full ${showImport ? "mb-4" : ""} `}
          readOnly={instance?.deleted || !isAdmin}
          mainClass="items-center flex"
          onChange={async (_, { checked }) => {
            if (checked) {
              const { payload } = await loadAgideskTeams();
              if (payload && !payload.error) {
                navigate(`${location.pathname}#agidesk-import`);
                setShowImport(checked || false);
              }
            } else {
              navigate(location.pathname);
              setShowImport(checked || false);
            }
          }}
          labelClass="ml-4"
          label="Exibir importação de equipes e agentes"
        />
        {showImport ? (
          <>
            <TeamsUsers
              setImportData={setImportTeamUsers}
              importData={importTeamUsers}
            />
            {importTeamUsers.length > 0 ? getButton() : null}
          </>
        ) : null}
        {/* MODALS */}
        {showModal && setShowModal ? (
          <RelationModal
            title="Selecionar administradores"
            icon="las la-users-cog"
            setShowModal={setShowModal}
            importList={importTeamUsers}
            setImportList={setImportTeamUsers}
            setShowImport={setShowImport}
          />
        ) : null}

        {showImport ? (
          <div className="flex justify-between rounded-md">
            <SelectPerPage
              value={limitAgideskTeams}
              pagination={5}
              onChange={(e) => {
                setLimitAgideskTeams(+e.target.value);
                setActiveTeamsPage(1);
                setSkipTeamsPage(0);
                loadAgideskTeams(0, +e.target.value);
                handleCollapses();
              }}
            />
            <PaginationComponent
              table
              totalPages={pageTeamsCount}
              activePage={activeTeamsPage}
              onPageChange={(_, _data) => {
                if (_data.activePage) {
                  setActiveTeamsPage(+_data.activePage);
                  setSkipTeamsPage(limitAgideskTeams * (+_data.activePage - 1));
                  navigate(`${location.pathname}#agidesk-import`);
                  loadAgideskTeams(limitAgideskTeams * (+_data.activePage - 1));
                  handleCollapses();
                }
              }}
            />
          </div>
        ) : null}
      </>
    ),
    agidesk: (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full mt-4"
            // icon="linkify"
            // iconPosition="left"
            label="Nome"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.name}
            required
            minLength={2}
            onChange={(e, { value }) => {
              // if (value.length >= 2) {
              handleOnChangeInfo({
                field: "name",
                value,
              });
              // }
            }}
            type="text"
            name="url"
            id="url"
            key="url"
            variant="outlined"
            // size="small"
            placeholder="URL do ambiente"
          />
          <ActiveDropdown
            disabled={instance?.deleted || !isAdmin}
            className="w-full mt-6"
            fluid
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full mt-4"
            // icon="computer"
            // iconPosition="left"
            label="URL do ambiente"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.url}
            required
            // minLength={2}
            onChange={(e, { value }) => {
              // if (value.length >= 2) {
              handleOnChangeInfo({
                field: "url",
                value,
              });
              // }
            }}
            type="text"
            name="url"
            // id="url"
            key="url"
            variant="outlined"
            // size="small"
            placeholder="URL do ambiente"
          />
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full mt-4"
            // icon="building"
            // iconPosition="left"
            label="ID da empresa"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.slug}
            required
            minLength={2}
            onChange={(e, { value }) => {
              // if (value.length >= 2) {
              handleOnChangeInfo({
                field: "slug",
                value,
              });
              // }
            }}
            type="text"
            name="slug"
            id="slug"
            key="slug"
            variant="outlined"
            // size="small"
            placeholder="ID da empresa"
          />
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full mt-4"
            // icon="at"
            // iconPosition="left"
            label="E-mail da conta Agidesk"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.email}
            required
            // minLength={2}
            onChange={(e, { value }) => {
              // if (value.length >= 2) {
              handleOnChangeInfo({
                field: "email",
                value,
              });
              // }
            }}
            type="text"
            name="email"
            id="email"
            key="email"
            variant="outlined"
            // size="small"
            placeholder="E-mail da conta Agidesk"
          />
          <Form.Input
            autoComplete="off"
            className="relative w-full mt-4 md:w-full"
            label="Chave de acesso"
            value={instance?.fields?.appkey}
            readOnly={instance?.deleted || !isAdmin}
            required
            fluid
            type={showHide["agidesk-appkey"] ? "input" : "password"}
            onChange={(e, { value }) => {
              if (value.length >= 0) {
                handleOnChangeInfo({
                  field: "appkey",
                  value,
                });
              }
            }}
            name="appkey"
            id="appkey"
            key="appkey"
            variant="outlined"
            placeholder="Chave de acesso"
            action
          >
            <input />
            <ShowHideButton
              title={`${
                !showHide["agidesk-appkey"] ? "Mostrar" : "Esconder"
              } chave de acesso`}
              hide={showHide["agidesk-appkey"]}
              onClick={(e) => handleShowHide(e, "agidesk-appkey")}
            />
          </Form.Input>
        </Form.Group>
        {!instance?.deleted ? (
          <div className="flex mt-6">
            <Button
              minWidth
              label="Salvar e validar"
              onClick={async () => {
                if (save) {
                  save();
                }
              }}
              icon="las la-check"
              disabled={
                typeof instance?.fields?.name?.length === "undefined" ||
                typeof instance?.fields?.url?.length === "undefined" ||
                typeof instance?.fields?.email?.length === "undefined" ||
                typeof instance?.fields?.appkey?.length === "undefined" ||
                instance?.active === false ||
                (instance?.fields?.validate && !_changingValue.current)
              }
            />
          </div>
        ) : null}
      </Form>
    ),
    agibot: (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            className="w-full md:w-full mt-4"
            // icon="linkify"
            // iconPosition="left"
            label="Nome"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.name}
            required
            minLength={2}
            onChange={(e, { value }) => {
              // if (value.length >= 2) {
              handleOnChangeInfo({
                field: "name",
                value,
              });
              // }
            }}
            type="text"
            name="url"
            id="url"
            key="url"
            variant="outlined"
            // size="small"
            placeholder="Nome"
          />
          <ActiveDropdown
            disabled={instance?.deleted || !isAdmin}
            className="w-full mt-4"
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>

        <Form.Group className="mt-4">
          <Form.Input
            className="relative w-full mt-4 md:w-full"
            // icon="lock"
            // iconPosition="left"
            label="Chave de acesso"
            readOnly={instance?.deleted || !isAdmin}
            value={instance?.fields?.appkey}
            required
            fluid
            type={showHide["agibot-appkey"] ? "input" : "password"}
            onChange={(e, { value }) => {
              if (value.length >= 0) {
                handleOnChangeInfo({
                  field: "appkey",
                  value,
                });
              }
            }}
            name="appkey"
            id="appkey"
            key="appkey"
            variant="outlined"
            placeholder="Chave de acesso"
            action
          >
            <input />
            <ShowHideButton
              title={`${
                !showHide["agibot-appkey"] ? "Mostrar" : "Esconder"
              } chave de acesso`}
              hide={showHide["agibot-appkey"]}
              onClick={(e) => handleShowHide(e, "agibot-appkey")}
            />
          </Form.Input>
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Input
            className="relative w-full mt-4 md:w-full"
            // icon="address card"
            // iconPosition="left"
            label="Id do Bot"
            value={instance?.fields?.botid}
            readOnly={instance?.deleted || !isAdmin}
            required
            fluid
            type="text"
            name="botid"
            id="botid"
            key="botid"
            variant="outlined"
            // size="small"
            onChange={(e, { value }) => {
              if (value.length >= 0) {
                handleOnChangeInfo({
                  field: "botid",
                  value,
                });
              }
            }}
            placeholder="Id do Bot"
          />
          <Form.Input
            className="relative w-full mt-4 md:w-full"
            // icon="globe"
            // iconPosition="left"
            label="Url da API"
            value={instance?.fields?.apiurl}
            readOnly={instance?.deleted || !isAdmin}
            required
            fluid
            type="url"
            name="apiurl"
            id="apiurl"
            key="apiurl"
            variant="outlined"
            // size="small"
            onChange={(e, { value }) => {
              if (value.length >= 0) {
                handleOnChangeInfo({
                  field: "apiurl",
                  value,
                });
              }
            }}
            placeholder="Url da API"
          />
        </Form.Group>
        {!instance?.deleted ? (
          <Form.Group className="mt-4">
            <Form.Input
              className="relative w-full mt-4 md:w-full"
              // icon="linkify"
              // iconPosition="left"
              readOnly
              label="Webhook"
              value={webhookUrl}
              fluid
              type="input"
              name="webhook"
              id="webhook"
              key="webhook"
              variant="outlined"
              // size="small"
              placeholder="webhook"
              action
            >
              <input />
              <CopyButton _id="webhook" />
            </Form.Input>
          </Form.Group>
        ) : null}
        {!instance?.deleted ? (
          <div className="flex mt-6">
            <Button
              minWidth
              label="Salvar e validar"
              onClick={async () => {
                if (save) {
                  save();
                }
              }}
              icon="las la-check"
              disabled={
                typeof instance?.fields?.name === "undefined" ||
                instance?.fields?.name?.length < 2 ||
                typeof instance?.fields?.appkey === "undefined" ||
                instance?.fields?.appkey?.length === 0 ||
                typeof instance?.fields?.apiurl === "undefined" ||
                instance?.fields?.apiurl?.length === 0 ||
                typeof instance?.fields?.botid === "undefined" ||
                instance?.fields?.accountid?.length === 0 ||
                instance?.active === false ||
                (instance?.fields?.validate && !_changingValue.current)
              }
            />
          </div>
        ) : null}
      </Form>
    ),
    teams: (
      <>
        <div key="teams" className="flex flex-col w-full text-justify">
          <label>Equipes relacionadas</label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.teams?.length ? "" : "justify-center"
            }`}
          >
            {instance?.teams?.length ? (
              instance?.teams?.map((team: ITeam) => {
                if (typeof team._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${team._id}team`}
                      type="team"
                      instance={team}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div key="none" className="text-center mt-4">
                Nenhuma equipe
              </div>
            )}
          </div>
        </div>
        {!instance?.deleted && isAdmin
          ? creationAndRelationButtons({
              onClickCreation: () => {
                if (location.pathname.includes("/settings/profile"))
                  navigate("/settings/profile#teams");
                if (setVisible) setVisible(true);
              },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    customers: (
      <>
        <div className="flex flex-col w-full text-justify">
          <label>Clientes relacionados</label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.customers?.length ? "" : "justify-center"
            }`}
          >
            {instance?.customers?.length ? (
              instance?.customers?.map((customer: ICustomer) => {
                if (typeof customer._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${customer._id}customer`}
                      type="customer"
                      instance={customer}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div className="my-4 text-center">Nenhum cliente</div>
            )}
          </div>
        </div>
        {!instance?.deleted
          ? creationAndRelationButtons({
              onClickCreation: () => {
                if (location.pathname.includes("/settings/profile"))
                  navigate("/settings/profile#customers");
                if (setVisible) setVisible(true);
              },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    "team-users": (
      <>
        <div className="flex flex-col w-full text-justify">
          <label>Agentes relacionados</label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.users?.length ? "" : "justify-center"
            }`}
          >
            {instance?.users?.length ? (
              instance?.users?.map((_user: IUser) => {
                if (typeof _user._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${_user._id}user`}
                      type="user"
                      instance={_user}
                      avatar
                    />
                  );
                }
                return null;
              })
            ) : (
              <div className="my-4 text-center">Nenhum agente</div>
            )}
          </div>
        </div>
        {!instance?.deleted && isAdmin
          ? creationAndRelationButtons({
              onClickCreation: () => {
                if (setVisible) setVisible(true);
              },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    "customer-contacts": (
      <>
        <div className="flex flex-col w-full text-justify">
          <label>Contatos relacionados</label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.contacts?.length ? "" : "justify-center"
            }`}
          >
            {instance?.contacts?.length ? (
              instance?.contacts?.map((contact: IUser) => {
                if (typeof contact._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${contact._id}contact`}
                      type="contact"
                      instance={contact}
                      avatar
                    />
                  );
                }
                return null;
              })
            ) : (
              <div className="my-4 text-center">Nenhum contato</div>
            )}
          </div>
        </div>
        {!instance?.deleted
          ? creationAndRelationButtons({
              onClickCreation: () => {
                if (setVisible) setVisible(true);
              },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    "team-chatusers": (
      <>
        <AssignedtypeDropdown
          disabled={instance?.deleted || !isAdmin}
          className="w-full"
          fluid
          defaultValue={instance?.assignedtype}
          onChange={(e, { value }) => {
            handleOnChangeInfo({
              field: "assignedtype",
              value,
            });
          }}
        />
        {!instance?.deleted && instance?.assignedtype === AssignedType.AGENT ? (
          <>
            <Form.Group>
              <Toggle
                containerClass="flex w-3/4 mt-6"
                defaultChecked={instance?.chatlimit || false}
                readOnly={instance?.deleted || !isAdmin}
                mainClass=""
                onChange={(_, { checked }) => {
                  handleOnChangeInfo({
                    field: "chatlimit",
                    value: checked || false,
                  });
                }}
                labelClass="ml-4 mb-2"
                label="Limitar conversas"
              />
            </Form.Group>
            {!instance?.deleted && instance?.chatlimit ? (
              <Form.Input
                autoComplete="off"
                readOnly={instance?.deleted || !isAdmin}
                className="w-full"
                label="Limite por agente"
                placeholder="Limite por agente"
                defaultValue={instance?.chatlimitsize || 0}
                type="text"
                onChange={(e, { value }) => {
                  handleOnChangeInfo({
                    field: "chatlimitsize",
                    value: +value,
                  });
                }}
              />
            ) : null}
          </>
        ) : null}
        {!instance?.deleted ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable direction="vertical" droppableId="droppable">
              {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="pb-2 pt-8 md:w-full"
                >
                  <PopupComponent
                    className="flex items-center mb-1"
                    label="Agentes disponíveis"
                    content={
                      <h1 className="font-semibold text-[12px]">
                        Você pode ordenar os agentes arrastando eles. <br />A
                        ordem determina quem receberá primeiro uma conversa.
                      </h1>
                    }
                  />
                  {instance?.chatusers && instance.chatusers.length > 0 ? (
                    <>
                      {showAvailableUsersList()}
                      {provided.placeholder}
                    </>
                  ) : (
                    <div className="my-4 text-center">Nenhum agente</div>
                  )}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
        {!instance?.deleted && isAdmin ? (
          <Button
            minWidth
            label="Relacionar"
            onClick={() => {
              if (setShowChatModal) setShowChatModal(true);
            }}
            icon="las la-link"
            inverted
          />
        ) : null}
      </>
    ),
    "company-info": (
      <>
        <FormAvatar
          instance={
            typeof instance?.avatar?._id !== "undefined"
              ? instance
              : { ...instance, name: instance?.name || tenantID }
          }
          onRemove={!isAdmin ? undefined : handleDeleteAvatar}
          onChange={!isAdmin ? undefined : handleAvatarChange}
        />
        <Form className="mt-4">
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              readOnly={!isAdmin}
              className="w-full md:w-full"
              label="Nome"
              fluid
              placeholder="Nome"
              defaultValue={instance?.name}
              type="text"
              onChange={(e, { value }) => {
                if (value.length > 1) {
                  handleOnChangeInfo({
                    field: "name",
                    value,
                  });
                } else {
                  handleOnChangeInfo({
                    field: "name",
                    value: "",
                  });
                }
              }}
            />
            <Form.Input
              autoComplete="off"
              readOnly={!isAdmin}
              className="w-full md:w-full"
              label="CNPJ"
              fluid
              children={
                <ReactInputMask
                  maskPlaceholder={null}
                  mask="99.999.999/9999-99"
                  defaultValue={instance?.code}
                  placeholder="CNPJ"
                  type="text"
                  onChange={(e) => {
                    handleOnChangeInfo({
                      field: "code",
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              readOnly={!isAdmin}
              className="w-full md:w-full"
              label="Endereço"
              fluid
              placeholder="Endereço"
              defaultValue={instance?.address}
              type="text"
              onChange={(e, { value }) => {
                handleOnChangeInfo({
                  field: "address",
                  value,
                });
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <SegmentDropdown
              disabled={!isAdmin}
              defaultValue={instance?.segment}
              onChange={(e, { value }) => {
                handleOnChangeInfo({
                  field: "segment",
                  value,
                });
              }}
            />
            {/* <PhoneMaskInput
              key="phone"
              readOnly={!isAdmin}
              defaultValue={instance?.phone}
              onChange={(e) => {
                handleOnChangeInfo({
                  field: "phone",
                  value: e.target.value?.replace(/[^0-9.]/g, ""),
                });
              }}
            /> */}
            <PhoneFlagInput
              key="phone"
              readOnly={!isAdmin}
              defaultValue={instance?.phone}
              onChange={(value: string) => {
                handleOnChangeInfo({
                  field: "phone",
                  value: value.replace(/[^0-9.]/g, ""),
                });
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="new-email"
              readOnly={!isAdmin}
              className="w-full md:w-full"
              label="E-mail do contato"
              fluid
              placeholder="E-mail do contato"
              defaultValue={instance?.contactemail}
              type="email"
              onChange={(e, { value }) => {
                handleOnChangeInfo({
                  field: "contactemail",
                  value,
                });
              }}
            />
            {/* <PhoneMaskInput
              key="contactphone"
              readOnly={!isAdmin}
              label="Telefone do contato"
              defaultValue={instance?.contactphone}
              onChange={(e) => {
                handleOnChangeInfo({
                  field: "contactphone",
                  value: e.target.value?.replace(/[^0-9.]/g, ""),
                });
              }}
            /> */}
            <PhoneFlagInput
              key="contactphone"
              readOnly={!isAdmin}
              label="Telefone do contato"
              defaultValue={instance?.contactphone}
              onChange={(value: string) => {
                handleOnChangeInfo({
                  field: "contactphone",
                  value: value.replace(/[^0-9.]/g, ""),
                });
              }}
            />
          </Form.Group>
        </Form>
      </>
    ),
    officehours: (
      <>
        {rows.length > 0 && selectedRow ? (
          <Table
            id="table"
            {...getTableProps({
              className: "p-0 m-0",
            })}
            selectable
            basic="very"
          >
            <TableHeader
              headerGroups={headerGroups}
              className="border-l-4 border-l-white"
            />
            <TableBody
              type="officehours"
              prepareRow={prepareRow}
              getTableBodyProps={getTableBodyProps}
              page={rows}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              setShowModal={setShowModal}
            />
          </Table>
        ) : (
          <div className="flex w-full h-full justify-center p-6">
            Nenhum resultado
          </div>
        )}
        <div className="flex mt-6">
          {!selectedOfficehour?.deleted &&
          selectedOfficehour?.active &&
          isAdmin ? (
            <Button
              minWidth
              label="Novo horário"
              onClick={() => {
                if (setVisible) setVisible(true);
              }}
              icon="las la-plus"
            />
          ) : null}
          {!selectedOfficehour?.deleted &&
          isAdmin &&
          selectedFlatRows.length > 0 ? (
            <Button
              minWidth
              red
              disabled={false}
              hoverColor
              inverted
              extraClass="ml-[10px]"
              icon="las la-trash"
              label={`Remover (${selectedFlatRows.length})`}
              onClick={() => {
                if (setShowDeleteModal) setShowDeleteModal(true);
              }}
            />
          ) : null}
        </div>
      </>
    ),
    defaultbot: (
      <>
        <FormAvatar
          instance={instance}
          onRemove={!isAdmin ? undefined : handleDeleteAvatar}
          onChange={!isAdmin ? undefined : handleAvatarChange}
        />
        <Form>
          <Form.Input
            autoComplete="off"
            readOnly={!isAdmin}
            label="Nome"
            placeholder="Nome"
            type="text"
            defaultValue={instance?.name || ""}
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "name",
                value,
              });
            }}
          />
          <div className="mt-6" />
          <Form.TextArea
            readOnly={!isAdmin}
            style={{ resize: "none" }}
            label="Mensagem de entrada na fila de espera"
            placeholder="Mensagem de entrada na fila de espera"
            type="text"
            defaultValue={instance?.welcomemsg || ""}
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "welcomemsg",
                value,
              });
            }}
          />
          <div className="mt-6" />
          <Form.TextArea
            readOnly={!isAdmin}
            style={{ resize: "none" }}
            label="Mensagem de indisponibilidade (fora do horário de atendimento)"
            placeholder="Mensagem de indisponibilidade (fora do horário de atendimento)"
            type="text"
            defaultValue={instance?.unavailablemsg || ""}
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "unavailablemsg",
                value,
              });
            }}
          />
          <div className="mt-6" />
          <Form.TextArea
            readOnly={!isAdmin}
            style={{ resize: "none" }}
            label="Mensagem de indisponibilidade de agentes (Todos os agentes da equipe estão offline)"
            placeholder="Mensagem de indisponibilidade de agentes (Todos os agentes da equipe estão offline)"
            type="text"
            defaultValue={instance?.userofflinemsg || ""}
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "userofflinemsg",
                value,
              });
            }}
          />
        </Form>
      </>
    ),
    defaultagent: (
      <Form>
        <Dropdown
          id="dropdown-settings-defaultagent"
          loading={
            isLoadingDropdown && focusId === "dropdown-settings-defaultagent"
          }
          gotoTitle={
            selectedSettings?.defaultagent?.name
              ? `Ir para ${selectedSettings?.defaultagent?.name} (${selectedSettings?.defaultagent?.email})`
              : undefined
          }
          gotoClick={
            selectedSettings?.defaultagent?.name
              ? () => {
                  if (selectedSettings?.defaultagent?._id === user?._id) {
                    navigate(`/settings/profile`);
                  } else {
                    navigate(
                      `/settings/user/${selectedSettings?.defaultagent?._id}`
                    );
                  }
                }
              : undefined
          }
          className="w-full -mb-[4px]"
          label="Usuário padrão para ações anônimas"
          required
          fluid
          search
          selection
          defaultValue={instance?._id}
          options={
            optionsInstance.length > 0
              ? optionsInstance
              : [
                  {
                    key: 0,
                    text: instance?.name,
                    value: instance?._id,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{instance?.name} </h1>
                        {typeof instance?.email !== "undefined" ? (
                          <h1 className="text-gray-text">
                            ({instance?.email})
                          </h1>
                        ) : null}
                      </div>
                    ),
                  },
                ]
          }
          onSearchChange={async (e: DropdownSearchInputProps) => {
            setIsLoadingDropdown(true);
            const payload = await usersService.searchAgents({
              skip: 0,
              limit: 10,
              filter: e.target.value,
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsInstance: ddOptions[] = optionsInstance;
            payload.results.map((_user: IUser) => {
              if (
                _optionsInstance?.filter(
                  (_opUser) => _opUser.key === _user._id
                )[0]
              ) {
                return false;
              }
              _optionsInstance.push({
                key: _user._id,
                text: _user.name,
                value: _user._id,
                content: (
                  <div className="flex">
                    <h1 className="mr-2">{_user?.name}</h1>
                    {typeof _user?.email !== "undefined" ? (
                      <h1 className="text-gray-text">({_user?.email})</h1>
                    ) : null}
                  </div>
                ),
              });
              return true;
            });
            setOptionsInstance(_optionsInstance);
          }}
          onOpen={async () => {
            setIsLoadingDropdown(true);
            const payload = await usersService.searchAgents({
              skip: 0,
              limit: 10,
              filter: "",
              deleted: false,
            });
            setIsLoadingDropdown(false);
            const _optionsInstance: object[] = [];
            payload.results.map((_user: IUser) =>
              _optionsInstance.push({
                key: _user._id,
                text: _user.name,
                value: _user._id,
                content: (
                  <div className="flex">
                    <h1 className="mr-2">{_user?.name}</h1>
                    {typeof _user?.email !== "undefined" ? (
                      <h1 className="text-gray-text">({_user?.email})</h1>
                    ) : null}
                  </div>
                ),
              })
            );
            setOptionsInstance(_optionsInstance);
          }}
          onFocus={() => {
            setFocusId("dropdown-settings-defaultagent");
          }}
          onBlur={() => {
            setFocusId("");
          }}
          placeholder="Usuário para ações anônimas"
          onChange={async (e, { value }) => {
            const target = e.target as HTMLInputElement;
            const _value: DropdownUser = {
              _id: value as string,
              name: target.innerText,
            };
            handleOnChangeInfo({
              field: "defaultagent",
              value: _value,
            });
          }}
        />
      </Form>
    ),
    inactivetime: (
      <Form>
        <PopupComponent
          className="flex items-center mb-1"
          label="Tempo de inatividade do contato"
          required
          content={<h1 className="font-semibold text-[12px]">Máximo: 23:59</h1>}
        />
        <Form.Input
          autoComplete="off"
          readOnly={!isAdmin}
          className="w-full md:w-full"
          fluid
          min="00:01"
          max="23:59"
          required
          children={
            <ReactInputMask
              maskPlaceholder={null}
              readOnly={!isAdmin}
              placeholder="Tempo de inatividade do contato"
              required
              mask={[
                /[0-2]/,
                time[0] === "2" ? /[0-3]/ : /[0-9]/,
                ":",
                /[0-5]/,
                /[0-9]/,
              ]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleOnChangeInfo({
                  field: "inactivetime",
                  value: e.target.value,
                });
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const _time = parseBlurTime(e.target.value);
                setTime(_time);
                dispatch(
                  reselectSettings({
                    ...selectedSettings,
                    inactivetime: _time,
                  })
                );
              }}
              value={instance || ""}
            />
          }
        />
      </Form>
    ),
    "settings-officehour": (
      <Dropdown
        id="dropdown-settings-officehour"
        gotoTitle={
          selectedSettings?.officehour?._id
            ? `Ir para ${selectedSettings?.officehour?.name}`
            : undefined
        }
        gotoClick={
          selectedSettings?.officehour?._id && isAdmin
            ? () =>
                navigate(
                  `/settings/officehour/${selectedSettings?.officehour?._id}`
                )
            : undefined
        }
        loading={
          isLoadingDropdown && focusId === "dropdown-settings-officehour"
        }
        className="w-full"
        label="Horário de atendimento"
        fluid
        search
        selection
        defaultValue={instance?._id || selectedSettings?.officehour?._id}
        options={
          optionsInstance.length > 0
            ? optionsInstance
            : [
                {
                  key: 0,
                  text: instance?.name || selectedSettings?.officehour?.name,
                  value: instance?._id || selectedSettings?.officehour?._id,
                },
              ]
        }
        onSearchChange={async (e: DropdownSearchInputProps) => {
          setIsLoadingDropdown(true);
          const payload = await officehoursService.search({
            skip: 0,
            limit: 10,
            filter: e.target.value,
            deleted: false,
          });
          setIsLoadingDropdown(false);
          const _optionsInstance: ddOptions[] = optionsInstance;
          payload.results.forEach((_officehour: IOfficehour) => {
            _optionsInstance.push({
              key: _officehour._id,
              text: _officehour.name,
              value: _officehour._id,
            });
          });
          setOptionsInstance(_optionsInstance);
        }}
        onOpen={async () => {
          setIsLoadingDropdown(true);
          const payload = await officehoursService.search({
            skip: 0,
            limit: 10,
            filter: "",
            deleted: false,
          });
          setIsLoadingDropdown(false);
          const _optionsInstance: object[] = [];
          payload.results.forEach((_officehour: IOfficehour) => {
            _optionsInstance.push({
              key: _officehour._id,
              text: _officehour.name,
              value: _officehour._id,
            });
          });
          setOptionsInstance(_optionsInstance);
        }}
        onFocus={() => {
          setFocusId("dropdown-settings-officehour");
        }}
        onBlur={() => {
          setFocusId("");
        }}
        placeholder="Horário de atendimento"
        onChange={async (e, { value }) => {
          const target = e.target as HTMLInputElement;
          const _value: { _id: string; name: string } = {
            _id: value as string,
            name: target.innerText,
          };
          handleOnChangeInfo({
            field: "officehour",
            value: _value,
          });
        }}
      />
    ),
    "settings-survey": (
      <Form>
        <Form.Group widths="equal">
          <Toggle
            containerClass="flex w-full ml-2"
            defaultChecked={!selectedSettings?.sendsurvey}
            mainClass="items-center flex"
            onChange={(_, { checked }) => {
              handleOnChangeInfo({
                field: "sendsurvey",
                value: !checked,
              });
            }}
            labelClass="ml-4"
            label={t("toggle.survey.notsendsurvey")}
          />
        </Form.Group>
        {selectedSettings?.sendsurvey ? (
          <Dropdown
            id="dropdown-settings-survey"
            gotoTitle={
              selectedSettings?.survey?._id
                ? `Ir para ${selectedSettings?.survey?.name}`
                : undefined
            }
            gotoClick={
              selectedSettings?.survey?._id && isAdmin
                ? () =>
                    navigate(
                      `/settings/survey/${selectedSettings?.survey?._id}`
                    )
                : undefined
            }
            loading={
              isLoadingDropdown && focusId === "dropdown-settings-survey"
            }
            className="w-full"
            label="Pesquisa de satisfação"
            fluid
            search
            selection
            defaultValue={instance?._id || selectedSettings?.survey?._id}
            options={
              optionsInstance.length > 0
                ? optionsInstance
                : [
                    {
                      key: 0,
                      text: instance?.name || selectedSettings?.survey?.name,
                      value: instance?._id || selectedSettings?.survey?._id,
                    },
                  ]
            }
            onSearchChange={async (e: DropdownSearchInputProps) => {
              setIsLoadingDropdown(true);
              const payload = await surveysService.search({
                skip: 0,
                limit: 10,
                filter: e.target.value,
                deleted: false,
              });
              setIsLoadingDropdown(false);
              const _optionsInstance: ddOptions[] = optionsInstance;
              payload.results.forEach((_survey: ISurvey) => {
                _optionsInstance.push({
                  key: _survey._id,
                  text: _survey.name,
                  value: _survey._id,
                });
              });
              setOptionsInstance(_optionsInstance);
            }}
            onOpen={async () => {
              setIsLoadingDropdown(true);
              const payload = await surveysService.search({
                skip: 0,
                limit: 10,
                filter: "",
                deleted: false,
              });
              setIsLoadingDropdown(false);
              const _optionsInstance: object[] = [];
              payload.results.forEach((_survey: ISurvey) => {
                _optionsInstance.push({
                  key: _survey._id,
                  text: _survey.name,
                  value: _survey._id,
                });
              });
              setOptionsInstance(_optionsInstance);
            }}
            onFocus={() => {
              setFocusId("dropdown-settings-survey");
            }}
            onBlur={() => {
              setFocusId("");
            }}
            placeholder="Pesquisa de satisfação"
            onChange={async (e, { value }) => {
              if (
                typeof value !== "undefined" &&
                value &&
                value.toString().length > 0
              ) {
                const target = e.target as HTMLInputElement;
                const _value: { _id: string; name: string } = {
                  _id: value as string,
                  name: target.innerText,
                };
                handleOnChangeInfo({
                  field: "survey",
                  value: _value,
                });
              }
            }}
          />
        ) : null}
      </Form>
    ),
    "settings-offlinemessage": (
      <Form>
        <Form.Group widths="equal">
          <Toggle
            containerClass="flex w-full ml-2"
            checked={toggle}
            readOnly={instance?.deleted || !isAdmin}
            mainClass="items-center flex"
            onChange={(_, { checked }) => {
              if (setToggle) setToggle(checked || false);
              if (
                !checked &&
                typeof selectedSettings?.offlinemessage?._id !== "undefined"
              ) {
                const _value = {
                  _id: null,
                };
                handleOnChangeInfo({
                  field: "settings-offlinemessage",
                  value: _value,
                });
              }
            }}
            labelClass="ml-4"
            label="Permitir atendimentos fora de horário no widget"
          />
        </Form.Group>
        {toggle ? (
          <>
            <Dropdown
              id="dropdown-settings-offlinemessage"
              gotoTitle={
                selectedSettings?.offlinemessage?._id
                  ? `Ir para ${selectedSettings?.offlinemessage?.name}`
                  : undefined
              }
              gotoClick={
                selectedSettings?.offlinemessage?._id && isAdmin
                  ? () =>
                      navigate(
                        `/settings/offlinemessage/${selectedSettings?.offlinemessage?._id}`
                      )
                  : undefined
              }
              loading={
                isLoadingDropdown &&
                focusId === "dropdown-settings-offlinemessage"
              }
              className="w-full"
              label="Selecione um perfil de atendimentos fora de horário"
              fluid
              search
              selection
              defaultValue={selectedSettings?.offlinemessage?._id}
              options={
                // eslint-disable-next-line no-nested-ternary
                optionsInstance.length > 0
                  ? optionsInstance
                  : selectedSettings?.offlinemessage?._id
                  ? [
                      {
                        key: 0,
                        text: selectedSettings?.offlinemessage?.name,
                        value: selectedSettings?.offlinemessage?._id,
                      },
                    ]
                  : []
              }
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await offlinemessagesService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsInstance: ddOptions[] = optionsInstance;
                payload.results
                  // ?.filter(
                  //   (_offlinemessage: IOfflinemessage) =>
                  //     typeof findInObject(
                  //       _offlinemessage?.message,
                  //       "type",
                  //       IOfflineType.WIDGET
                  //     ) !== "undefined"
                  // )
                  ?.filter((_offlinemessage: IOfflinemessage) =>
                    _offlinemessage?.message?.some(
                      (m) => m.type === IOfflineType.WIDGET
                    )
                  )
                  .forEach((_offlinemessage: IOfflinemessage) => {
                    _optionsInstance.push({
                      key: _offlinemessage._id,
                      text: _offlinemessage.name,
                      value: _offlinemessage._id,
                    });
                  });
                setOptionsInstance(_optionsInstance);
              }}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await offlinemessagesService.search({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsInstance: object[] = [];
                payload.results
                  // ?.filter(
                  //   (_offlinemessage: IOfflinemessage) =>
                  //     typeof findInObject(
                  //       _offlinemessage?.message,
                  //       "type",
                  //       IOfflineType.WIDGET
                  //     ) !== "undefined"
                  // )
                  ?.filter((_offlinemessage: IOfflinemessage) =>
                    _offlinemessage?.message?.some(
                      (m) => m.type === IOfflineType.WIDGET
                    )
                  )
                  .forEach((_offlinemessage: IOfflinemessage) => {
                    _optionsInstance.push({
                      key: _offlinemessage._id,
                      text: _offlinemessage.name,
                      value: _offlinemessage._id,
                    });
                  });
                setOptionsInstance(_optionsInstance);
              }}
              onFocus={() => {
                setFocusId("dropdown-settings-offlinemessage");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              placeholder="Mensagem de atendimento fora do horário"
              onChange={async (e, { value }) => {
                const target = e.target as HTMLInputElement;
                const _value: { _id: string; name: string } = {
                  _id: value as string,
                  name: target.innerText,
                };
                handleOnChangeInfo({
                  field: "settings-offlinemessage",
                  value: _value,
                });
              }}
            />
            <LinkButton
              label="Criar perfil de atendimento"
              onClick={() => navigate("/settings/offlinemessages")}
            />
          </>
        ) : null}
      </Form>
    ),
    widget: (
      <>
        <WidgetInput type="user" />
        {instance ? (
          <WidgetInput className="mt-6" type="contact" code={instance} />
        ) : null}
      </>
    ),
    whatsapp: (
      <Form id="whatsapp">
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            key="whatsAppName"
            required
            readOnly={instance?.deleted || !isAdmin}
            className="pt-2"
            type="input"
            label="Nome"
            id="whatsAppName"
            placeholder="Nome"
            fluid
            defaultValue={instance?.fields?.name || ""}
            onChange={(e, { value }) => {
              if (instance) {
                dispatch(
                  reselectExternalappWhatsApp({
                    ...instance,
                    fields: {
                      ...instance.fields,
                      name: value,
                    },
                  })
                );
              }
            }}
          />
          <ActiveDropdown
            disabled={instance?.deleted || !isAdmin}
            className="pt-2"
            fluid
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (instance) {
                dispatch(
                  reselectExternalappWhatsApp({
                    ...instance,
                    active: value,
                  })
                );
              }
            }}
          />
        </Form.Group>
        <Form.Group widths="equal">
          {/* <PhoneMaskInput
            label="Número"
            className="pt-2"
            key="displayphone"
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.displayphone || ""}
            onChange={(e) => {
              if (instance) {
                dispatch(
                  reselectExternalappWhatsApp({
                    ...instance,
                    fields: {
                      ...instance.fields,
                      displayphone: e.target.value,
                    },
                  })
                );
              }
            }}
          /> */}
          <PhoneFlagInput
            label="Número"
            className="pt-2"
            key="displayphone"
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.displayphone || ""}
            onChange={(value: string) => {
              if (instance) {
                dispatch(
                  reselectExternalappWhatsApp({
                    ...instance,
                    fields: {
                      ...instance.fields,
                      displayphone: value,
                    },
                  })
                );
              }
            }}
          />
          <Form.Input
            autoComplete="off"
            key="whatsAppPhone"
            required
            readOnly={instance?.deleted || !isAdmin}
            className="pt-2"
            type={showHide.whatsappAppPhone ? "input" : "password"}
            label="Identificação do número de telefone"
            id="whatsAppPhone"
            placeholder="Identificação do número de telefone"
            fluid
            defaultValue={instance?.fields?.phone || ""}
            onChange={(e, { value }) => {
              if (instance) {
                if (value !== instance?.fields?.phone) {
                  _changingValue.current = true;
                }
                dispatch(
                  reselectExternalappWhatsApp({
                    ...instance,
                    fields: {
                      ...instance.fields,
                      phone: value,
                    },
                  })
                );
              }
            }}
            action
          >
            <input />
            <ShowHideButton
              title={`${
                !showHide.whatsappAppPhone ? "Mostrar" : "Esconder"
              } identificação do número de telefone`}
              hide={showHide.whatsappAppPhone}
              onClick={(e) => handleShowHide(e, "whatsappAppPhone")}
            />
          </Form.Input>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            key="whatsAppBusinessAccountId"
            readOnly={instance?.deleted || !isAdmin}
            className="pt-2"
            required
            type={showHide.whatsAppBusinessAccountId ? "input" : "password"}
            label="Identificação da conta do WA Business"
            id="whatsAppBusinessAccountId"
            placeholder="Identificação da conta do WA Business"
            fluid
            defaultValue={instance?.fields?.wabaid || ""}
            onChange={(e, { value }) => {
              if (instance) {
                if (value !== instance?.fields?.wabaid) {
                  _changingValue.current = true;
                }
                dispatch(
                  reselectExternalappWhatsApp({
                    ...instance,
                    fields: {
                      ...instance.fields,
                      wabaid: value,
                    },
                  })
                );
              }
            }}
            action
          >
            <input />
            <ShowHideButton
              title={`${
                !showHide.whatsAppBusinessAccountId ? "Mostrar" : "Esconder"
              } identificação da conta do WA Business`}
              hide={showHide.whatsAppBusinessAccountId}
              onClick={(e) => handleShowHide(e, "whatsAppBusinessAccountId")}
            />
          </Form.Input>
          <Form.Input
            autoComplete="off"
            key="whatsAppVerifyToken"
            label="Token de verificação"
            className="pt-2"
            id="whatsAppVerifyToken"
            placeholder="Token de verificação"
            fluid
            value={instance?._id}
            action
          >
            <input />
            <CopyButton _id="whatsAppVerifyToken" />
          </Form.Input>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            key="whatsAppKey"
            required
            readOnly={instance?.deleted || !isAdmin}
            className="pt-2"
            type={showHide.whatsAppKey ? "input" : "password"}
            label="Token de acesso (permanente)"
            id="whatsAppKey"
            placeholder="Token de acesso (permanente)"
            fluid
            defaultValue={instance?.fields?.key || ""}
            onChange={(e, { value }) => {
              if (instance) {
                if (value !== instance?.fields?.key) {
                  _changingValue.current = true;
                }
                dispatch(
                  reselectExternalappWhatsApp({
                    ...instance,
                    fields: {
                      ...instance.fields,
                      key: value,
                    },
                  })
                );
              }
            }}
            action
          >
            <input />
            <ShowHideButton
              title={`${
                !showHide.whatsAppKey ? "Mostrar" : "Esconder"
              } token de acesso (permanente)`}
              hide={showHide.whatsAppKey}
              onClick={(e) => handleShowHide(e, "whatsAppKey")}
            />
          </Form.Input>
        </Form.Group>
        {!instance?.deleted && isAdmin ? (
          <div className="flex mt-6">
            <Button
              minWidth
              label="Salvar e validar"
              onClick={async () => {
                if (save) {
                  save();
                }
              }}
              icon="las la-check"
              disabled={
                typeof instance?.fields?.key?.length === "undefined" ||
                instance?.fields?.key?.length === 0 ||
                typeof instance?.fields?.phone?.length === "undefined" ||
                instance?.fields?.phone?.length === 0 ||
                // typeof instance?.fields?.verifytoken?.length === "undefined" ||
                typeof instance?.fields?.wabaid?.length === "undefined" ||
                instance?.fields?.wabaid?.length === 0 ||
                (instance?.fields?.validate && !_changingValue.current)
              }
            />
          </div>
        ) : null}
      </Form>
    ),
    "whatsapp-defaultteam": (
      <>
        <div key="teams" className="flex flex-col w-full text-justify">
          <Form>
            <Form.Input
              autoComplete="off"
              // label="Equipes disponíveis para atendimento pelo WhatsApp (Máximo: 10)"
              label="Equipes disponíveis para atendimento pelo WhatsApp"
              type="hidden"
              required
            />
          </Form>

          <div
            id="sidemenu"
            className={`-mt-8 flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              !instance?.deleted &&
              instance?.fields?.defaultteam &&
              instance?.fields?.defaultteam?.length
                ? ""
                : "justify-center"
            }`}
          >
            {!instance?.deleted &&
            instance?.fields?.defaultteam &&
            instance?.fields?.defaultteam?.length ? (
              instance?.fields?.defaultteam?.map((team: ITeam) => {
                if (typeof team._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${team._id}wa-defaultteam`}
                      type="team"
                      instance={team}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div key="none" className="text-center mt-4">
                Nenhuma equipe
              </div>
            )}
          </div>
        </div>
        {!instance?.deleted && isAdmin
          ? creationAndRelationButtons({
              // onClickCreation: () => {
              //   if (setVisible) setVisible(true);
              // },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    "whatsapp-offlinemessage": (
      <Form>
        <Toggle
          containerClass={`flex w-full ${toggle ? "mb-2" : ""} `}
          checked={toggle}
          readOnly={instance?.deleted || !isAdmin}
          mainClass="items-center flex"
          onChange={(_, { checked }) => {
            if (setToggle) setToggle(checked || false);
            if (
              !checked &&
              typeof selectedExternalAppWhatsApp?.fields?.offlinemessage
                ?._id !== "undefined"
            ) {
              const _value = {
                _id: undefined,
              };
              handleOnChangeInfo({
                field: "whatsapp-offlinemessage",
                value: _value,
              });
            }
          }}
          labelClass="ml-4 mb-4"
          label="Permitir atendimentos fora de horário no WhatsApp"
        />
        {toggle ? (
          <>
            <Dropdown
              id="dropdown-whatsapp-offlinemessage"
              gotoTitle={
                selectedExternalAppWhatsApp?.fields?.offlinemessage?._id
                  ? `Ir para ${selectedExternalAppWhatsApp?.fields?.offlinemessage?.name}`
                  : undefined
              }
              gotoClick={
                selectedExternalAppWhatsApp?.fields?.offlinemessage?._id &&
                isAdmin
                  ? () =>
                      navigate(
                        `/settings/offlinemessage/${selectedExternalAppWhatsApp?.fields?.offlinemessage?._id}`
                      )
                  : undefined
              }
              loading={
                isLoadingDropdown &&
                focusId === "dropdown-whatsapp-offlinemessage"
              }
              className="w-full"
              label="Selecione um perfil de atendimentos fora de horário"
              fluid
              search
              selection
              defaultValue={
                selectedExternalAppWhatsApp?.fields?.offlinemessage?._id
              }
              options={
                // eslint-disable-next-line no-nested-ternary
                optionsInstance.length > 0
                  ? optionsInstance
                  : selectedExternalAppWhatsApp?.fields?.offlinemessage?._id
                  ? [
                      {
                        key: 0,
                        text: selectedExternalAppWhatsApp?.fields
                          ?.offlinemessage?.name,
                        value:
                          selectedExternalAppWhatsApp?.fields?.offlinemessage
                            ?._id,
                      },
                    ]
                  : []
              }
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await offlinemessagesService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsInstance: ddOptions[] = optionsInstance;
                payload.results
                  // ?.filter(
                  //   (_offlinemessage: IOfflinemessage) =>
                  //     typeof findInObject(
                  //       _offlinemessage?.message,
                  //       "type",
                  //       IOfflineType.WHATSAPP
                  //     ) !== "undefined"
                  // )
                  ?.filter((_offlinemessage: IOfflinemessage) =>
                    _offlinemessage?.message?.some(
                      (m) => m.type === IOfflineType.WHATSAPP
                    )
                  )
                  .forEach((_offlinemessage: IOfflinemessage) => {
                    _optionsInstance.push({
                      key: _offlinemessage._id,
                      text: _offlinemessage.name,
                      value: _offlinemessage._id,
                    });
                  });
                setOptionsInstance(_optionsInstance);
              }}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await offlinemessagesService.search({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsInstance: object[] = [];
                payload.results
                  // ?.filter(
                  //   (_offlinemessage: IOfflinemessage) =>
                  //     typeof findInObject(
                  //       _offlinemessage?.message,
                  //       "type",
                  //       IOfflineType.WHATSAPP
                  //     ) !== "undefined"
                  // )
                  ?.filter((_offlinemessage: IOfflinemessage) =>
                    _offlinemessage?.message?.some(
                      (m) => m.type === IOfflineType.WHATSAPP
                    )
                  )
                  .forEach((_offlinemessage: IOfflinemessage) => {
                    _optionsInstance.push({
                      key: _offlinemessage._id,
                      text: _offlinemessage.name,
                      value: _offlinemessage._id,
                    });
                  });
                setOptionsInstance(_optionsInstance);
              }}
              onFocus={() => {
                setFocusId("dropdown-whatsapp-offlinemessage");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              placeholder="Mensagem de atendimento fora do horário"
              onChange={async (e, { value }) => {
                const target = e.target as HTMLInputElement;
                const _value: { _id: string; name: string } = {
                  _id: value as string,
                  name: target.innerText,
                };
                handleOnChangeInfo({
                  field: "whatsapp-offlinemessage",
                  value: _value,
                });
              }}
            />
            <LinkButton
              label="Criar perfil de atendimento"
              onClick={() => navigate("/settings/offlinemessages")}
            />
          </>
        ) : null}
      </Form>
    ),
    "agibot-phonelist": (
      <div key="phonelist" className="flex flex-col w-full text-justify">
        <PhoneList />
      </div>
    ),
    "agibot-defaultteam": (
      <>
        <div key="teams" className="flex flex-col w-full text-justify">
          <Form>
            <Form.Input
              autoComplete="off"
              label="Equipes disponíveis para atendimento pelo Agibot"
              type="hidden"
              required
            />
          </Form>

          <div
            id="sidemenu"
            className={`-mt-8 flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              !instance?.deleted &&
              instance?.fields?.defaultteam &&
              instance?.fields?.defaultteam?.length
                ? ""
                : "justify-center"
            }`}
          >
            {!instance?.deleted &&
            instance?.fields?.defaultteam &&
            instance?.fields?.defaultteam?.length ? (
              instance?.fields?.defaultteam?.map((team: ITeam) => {
                if (typeof team._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${team._id}bm-defaultteam`}
                      type="team"
                      instance={team}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div key="none" className="text-center mt-4">
                Nenhuma equipe
              </div>
            )}
          </div>
        </div>
        {!instance?.deleted && isAdmin
          ? creationAndRelationButtons({
              // onClickCreation: () => {
              //   if (setVisible) setVisible(true);
              // },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    // "agibot-offlinemessage": <OfflineMessage />,
    "agibot-offlinemessage": (
      <Form>
        <Toggle
          containerClass={`flex w-full ${toggle ? "mb-2" : ""} `}
          checked={toggle}
          readOnly={instance?.deleted || !isAdmin}
          mainClass="items-center flex"
          onChange={(_, { checked }) => {
            if (setToggle) setToggle(checked || false);
            if (
              !checked &&
              selectedExternalAppBotmaker?.fields?.offlinemessage?._id
            ) {
              const _value = {
                _id: undefined,
              };
              handleOnChangeInfo({
                field: "agibot-offlinemessage",
                value: _value,
              });
            }
          }}
          labelClass="ml-4 mb-4"
          label="Permitir atendimentos fora de horário no Agibot"
        />
        {toggle ? (
          <>
            <Dropdown
              id="dropdown-agibot-offlinemessage"
              gotoTitle={
                selectedExternalAppBotmaker?.fields?.offlinemessage?._id
                  ? `Ir para ${selectedExternalAppBotmaker?.fields?.offlinemessage?.name}`
                  : undefined
              }
              gotoClick={
                selectedExternalAppBotmaker?.fields?.offlinemessage?._id &&
                isAdmin
                  ? () =>
                      navigate(
                        `/settings/offlinemessage/${selectedExternalAppBotmaker?.fields?.offlinemessage?._id}`
                      )
                  : undefined
              }
              loading={
                isLoadingDropdown &&
                focusId === "dropdown-agibot-offlinemessage"
              }
              className="w-full"
              label="Selecione um perfil de atendimentos fora de horário"
              fluid
              search
              selection
              defaultValue={
                selectedExternalAppBotmaker?.fields?.offlinemessage?._id
              }
              options={
                // eslint-disable-next-line no-nested-ternary
                optionsInstance.length > 0
                  ? optionsInstance
                  : selectedExternalAppBotmaker?.fields?.offlinemessage?._id
                  ? [
                      {
                        key: 0,
                        text: selectedExternalAppBotmaker?.fields
                          ?.offlinemessage?.name,
                        value:
                          selectedExternalAppBotmaker?.fields?.offlinemessage
                            ?._id,
                      },
                    ]
                  : []
              }
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await offlinemessagesService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsInstance: ddOptions[] = optionsInstance;
                payload.results
                  // ?.filter(
                  //   (_offlinemessage: IOfflinemessage) =>
                  //     typeof findInObject(
                  //       _offlinemessage?.message,
                  //       "type",
                  //       IOfflineType.AGIBOT
                  //     ) !== "undefined"
                  // )
                  ?.filter((_offlinemessage: IOfflinemessage) =>
                    _offlinemessage?.message?.some(
                      (m) => m.type === IOfflineType.AGIBOT
                    )
                  )
                  .forEach((_offlinemessage: IOfflinemessage) => {
                    _optionsInstance.push({
                      key: _offlinemessage._id,
                      text: _offlinemessage.name,
                      value: _offlinemessage._id,
                    });
                  });
                setOptionsInstance(_optionsInstance);
              }}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await offlinemessagesService.search({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsInstance: object[] = [];
                payload.results
                  // ?.filter(
                  //   (_offlinemessage: IOfflinemessage) =>
                  //     typeof findInObject(
                  //       _offlinemessage?.message,
                  //       "type",
                  //       IOfflineType.AGIBOT
                  //     ) !== "undefined"
                  // )
                  .filter((_offlinemessage: IOfflinemessage) =>
                    _offlinemessage?.message?.some(
                      (m) => m.type === IOfflineType.AGIBOT
                    )
                  )
                  .forEach((_offlinemessage: IOfflinemessage) => {
                    _optionsInstance.push({
                      key: _offlinemessage._id,
                      text: _offlinemessage.name,
                      value: _offlinemessage._id,
                    });
                  });
                setOptionsInstance(_optionsInstance);
              }}
              onFocus={() => {
                setFocusId("dropdown-agibot-offlinemessage");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              placeholder="Mensagem de atendimento fora do horário"
              onChange={async (e, { value }) => {
                const target = e.target as HTMLInputElement;
                const _value: { _id: string; name: string } = {
                  _id: value as string,
                  name: target.innerText,
                };
                handleOnChangeInfo({
                  field: "agibot-offlinemessage",
                  value: _value,
                });
              }}
            />
            <LinkButton
              label="Criar perfil de atendimento"
              onClick={() => navigate("/settings/offlinemessages")}
            />
          </>
        ) : null}
      </Form>
    ),
    "settings-defaultteam": (
      <>
        <div key="teams" className="flex flex-col w-full text-justify">
          <label>
            {/* Equipes disponíveis para atendimento pelo widget (Máximo: 10) */}
            Equipes disponíveis para atendimento pelo widget
          </label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.defaultteam && instance?.defaultteam?.length
                ? ""
                : "justify-center"
            }`}
          >
            {instance?.defaultteam && instance?.defaultteam?.length ? (
              instance?.defaultteam?.map((team: ITeam) => {
                if (typeof team._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${team._id}defaultteam`}
                      type="team"
                      instance={team}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div key="none" className="text-center mt-4">
                Nenhuma equipe
              </div>
            )}
          </div>
        </div>
        {isAdmin
          ? creationAndRelationButtons({
              // onClickCreation: () => {
              //   navigate("/settings/general#teams");
              //   if (setVisible) setVisible(true);
              // },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    "officehour-teams": (
      <>
        <div key="teams" className="flex flex-col w-full text-justify">
          <label>Equipes disponíveis neste horário de atendimento</label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.teams && instance?.teams?.length ? "" : "justify-center"
            }`}
          >
            {instance?.teams && instance?.teams?.length ? (
              instance?.teams?.map((team: ITeam) => {
                if (typeof team._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${team._id}officehourteam`}
                      type="team"
                      instance={team}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div key="none" className="text-center mt-4">
                Nenhuma equipe
              </div>
            )}
          </div>
        </div>
        {isAdmin
          ? creationAndRelationButtons({
              // onClickCreation: () => {
              //   navigate("/settings/general#teams");
              //   if (setVisible) setVisible(true);
              // },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    "survey-teams": (
      <>
        <div key="teams" className="flex flex-col w-full text-justify">
          <label>Equipes relacionadas</label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.teams?.length ? "" : "justify-center"
            }`}
          >
            {instance?.teams?.length ? (
              instance?.teams?.map((team: ITeam) => {
                if (typeof team._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${team._id}team`}
                      type="team"
                      instance={team}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div key="none" className="text-center mt-4">
                Nenhuma equipe
              </div>
            )}
          </div>
        </div>
        {!instance?.deleted && isAdmin
          ? creationAndRelationButtons({
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    "holiday-teams": (
      <>
        <div key="teams" className="flex flex-col w-full text-justify">
          <label>Equipes vinculadas a este feriado</label>
          <div
            id="sidemenu"
            className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
              instance?.teams && instance?.teams?.length ? "" : "justify-center"
            }`}
          >
            {instance?.teams && instance?.teams?.length ? (
              instance?.teams?.map((team: ITeam) => {
                if (typeof team._id !== "undefined") {
                  return (
                    <RelatedInstance
                      key={`${team._id}holidayteam`}
                      type="team"
                      instance={team}
                      avatar={false}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div key="none" className="text-center mt-4">
                Nenhuma equipe
              </div>
            )}
          </div>
        </div>
        {isAdmin
          ? creationAndRelationButtons({
              // onClickCreation: () => {
              //   navigate("/settings/general#teams");
              //   if (setVisible) setVisible(true);
              // },
              onClickRelation: () => {
                if (setShowModal) setShowModal(true);
              },
            })
          : null}
      </>
    ),
    interactive: (
      <Form>
        <PopupComponent
          className="flex items-center mb-1"
          label="Título"
          required
          content={
            <h1 className="font-semibold text-[12px]">Máximo 60 caracteres</h1>
          }
        />
        <Form.Input
          autoComplete="off"
          readOnly={instance?.deleted || !isAdmin}
          maxLength={60}
          placeholder="Título"
          type="text"
          defaultValue={instance?.fields?.header || ""}
          onChange={(e, { value }) => {
            handleOnChangeInfo({
              field: "header",
              value,
            });
          }}
        />
        <div className="mt-6" />
        <PopupComponent
          className="flex items-center mb-1"
          label="Descrição"
          required
          content={
            <h1 className="font-semibold text-[12px]">
              Máximo 1024 caracteres
            </h1>
          }
        />
        <Form.TextArea
          readOnly={instance?.deleted || !isAdmin}
          style={{ resize: "none" }}
          placeholder="Descrição"
          maxLength={1024}
          type="text"
          defaultValue={instance?.fields?.body || ""}
          onChange={(e, { value }) => {
            handleOnChangeInfo({
              field: "body",
              value,
            });
          }}
        />
        <div className="mt-6" />
        <PopupComponent
          className="flex items-center mb-1"
          label="Texto do botão de seleção"
          required
          content={
            <h1 className="font-semibold text-[12px]">Máximo 20 caracteres</h1>
          }
        />
        <Form.Input
          autoComplete="off"
          readOnly={instance?.deleted || !isAdmin}
          maxLength={20}
          placeholder="Texto do botão de seleção"
          type="text"
          defaultValue={instance?.fields?.button || ""}
          onChange={(e, { value }) => {
            handleOnChangeInfo({
              field: "button",
              value,
            });
          }}
        />
      </Form>
    ),
    "survey-info": (
      <Form className="-mb-6">
        <Form.Group widths="equal">
          <Form.Input
            autoComplete="off"
            className="w-full md:w-full"
            label="Nome"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.name}
            required
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "name",
                value,
              });
            }}
            onBlur={nameBlurHandler}
            error={instance?.name?.length < 2}
            type="text"
            name="name"
            variant="outlined"
            // size="small"
            placeholder="Nome completo"
          />
          <ActiveDropdown
            disabled={
              instance?.deleted || !isAdmin
              // || instance?.default
            }
            className="w-full mt-6"
            fluid
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>
        {!instance?.default ? (
          <Form.Group widths="equal">
            <Toggle
              containerClass="flex w-full ml-2 mt-2"
              defaultChecked={!instance?.sendsurvey}
              mainClass=""
              readOnly={instance?.deleted || !isAdmin}
              onChange={(_, { checked }) => {
                handleOnChangeInfo({
                  field: "sendsurvey",
                  value: !checked,
                });
              }}
              labelClass="ml-4 mb-2"
              label={t("toggle.survey.notsendsurvey")}
            />
          </Form.Group>
        ) : null}
        {instance?.active === true && !instance?.deleted ? (
          <Form.Group widths="equal">
            {!instance?.default ? (
              <Toggle
                containerClass="flex w-full ml-2 mb-2"
                defaultChecked={instance?.default || false}
                readOnly={instance?.deleted || !instance?.active || !isAdmin}
                mainClass=""
                onChange={async (_, { checked }) => {
                  const _survey = {
                    ...instance,
                    _id: instance?._id,
                    name: instance?.name,
                    active: instance?.active,
                    teams: instance?.teams?.map((_t: ITeam) => {
                      if (typeof _t === "string") return _t;
                      return _t._id;
                    }),
                    default: checked,
                    sendsurvey: checked,
                  };
                  const { payload } = await dispatch(updateSurvey({ _survey }));
                  dispatch(
                    reselectSurvey({
                      ...instance,
                      default: checked,
                      sendsurvey: checked,
                      updated_at: payload.updated_at,
                      updated_by: payload.updated_by,
                    })
                  );
                  handleOnChangeInfo({
                    field: "default",
                    value: checked || false,
                  });
                }}
                labelClass="ml-4 mb-2"
                label="Definir como pesquisa de satisfação padrão"
              />
            ) : (
              <PopupComponent
                className="ml-2 flex items-center w-full mb-4"
                label="Pesquisa definida como padrão do sistema"
                italic
                onClick={() => navigate("/settings/general#settings-survey")}
                content={
                  <h1>
                    Equipes sem pesquisa de satisfação definida enviarão esta
                    como padrão.
                  </h1>
                }
              />
            )}
          </Form.Group>
        ) : null}
      </Form>
    ),
    "survey-content": (
      <Form>
        <SurveyTypesDropdown
          required
          disabled={
            instance?.deleted ||
            (!isAdmin && !location.pathname.includes("contact"))
          }
          defaultValue={instance?.type}
          onChange={(e, { value }) => {
            handleOnChangeInfo({
              field: "type",
              value,
            });
          }}
        />
        <RtfEditor
          initialValue={instance?.content}
          disabled={
            instance?.deleted ||
            (!isAdmin && !location.pathname.includes("contact"))
          }
          height="400"
          id="surveycontent"
          label="Defina o corpo do e-mail a ser enviado"
          onChange={(e, value) => {
            handleOnChangeInfo({
              field: "content",
              value: value.getContent(),
            });
          }}
        />
        <div className="flex w-full my-2 justify-end">
          <Button
            minWidth
            label="Pré-visualizar"
            onClick={() => {
              if (setShowPreviewModal) setShowPreviewModal(true);
            }}
          />
        </div>
      </Form>
    ),
    vtex: (
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            className="w-full md:w-full mt-4"
            // icon="linkify"
            // iconPosition="left"
            label="Nome"
            fluid
            readOnly={instance?.deleted || !isAdmin}
            defaultValue={instance?.fields?.name}
            required
            minLength={2}
            onChange={(e, { value }) => {
              // if (value.length >= 2) {
              handleOnChangeInfo({
                field: "name",
                value,
              });
              // }
            }}
            type="text"
            name="url"
            id="url"
            key="url"
            variant="outlined"
            // size="small"
            placeholder="Nome"
          />
          <ActiveDropdown
            disabled={instance?.deleted || !isAdmin}
            className="w-full mt-4"
            defaultValue={instance?.active || false}
            onChange={(e, { value }) => {
              if (typeof value === "boolean")
                handleOnChangeInfo({
                  field: "active",
                  value,
                });
            }}
          />
        </Form.Group>

        <Form.Group className="mt-4">
          <Form.Input
            className="relative w-full mt-4 md:w-full"
            // icon="key"
            // iconPosition="left"
            label="Chave de acesso"
            readOnly={instance?.deleted || !isAdmin}
            value={instance?.fields?.appkey}
            required
            fluid
            type="input"
            onChange={(e, { value }) => {
              if (value.length >= 0) {
                handleOnChangeInfo({
                  field: "appkey",
                  value,
                });
              }
            }}
            name="appkey"
            id="appkey"
            key="appkey"
            variant="outlined"
            // size="small"
            placeholder="Chave de acesso"
          />
          <Form.Input
            className="relative w-full mt-4 md:w-full"
            // icon="lock"
            // iconPosition="left"
            label="Token de acesso"
            readOnly={instance?.deleted || !isAdmin}
            value={instance?.fields?.apptoken}
            required
            fluid
            type={showHide["vtex-appkey"] ? "input" : "password"}
            onChange={(e, { value }) => {
              if (value.length >= 0) {
                handleOnChangeInfo({
                  field: "apptoken",
                  value,
                });
              }
            }}
            name="apptoken"
            id="apptoken"
            key="apptoken"
            variant="outlined"
            // size="small"
            placeholder="Token de acesso"
            action
          >
            <input />
            <ShowHideButton
              title={`${
                !showHide["vtex-appkey"] ? "Mostrar" : "Esconder"
              } token de acesso`}
              hide={showHide["vtex-appkey"]}
              onClick={(e) => handleShowHide(e, "vtex-appkey")}
            />
          </Form.Input>
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Input
            className="relative w-full mt-4 md:w-full"
            // icon="address card"
            // iconPosition="left"
            label="Id da conta"
            value={instance?.fields?.accountid}
            readOnly={instance?.deleted || !isAdmin}
            required
            fluid
            type="text"
            name="accountid"
            id="accountid"
            key="accountid"
            variant="outlined"
            // size="small"
            onChange={(e, { value }) => {
              handleOnChangeInfo({
                field: "accountid",
                value,
              });
            }}
            placeholder="Id do conta"
          />
        </Form.Group>

        {!instance?.deleted ? (
          <div className="flex mt-6">
            <Button
              minWidth
              label="Salvar e validar"
              onClick={async () => {
                if (save) {
                  save();
                }
              }}
              icon="las la-check"
              disabled={
                typeof instance?.fields?.name === "undefined" ||
                instance?.fields?.name?.length < 2 ||
                typeof instance?.fields?.appkey === "undefined" ||
                instance?.fields?.appkey?.length === 0 ||
                typeof instance?.fields?.apptoken === "undefined" ||
                instance?.fields?.apptoken?.length === 0 ||
                typeof instance?.fields?.accountid === "undefined" ||
                instance?.fields?.accountid?.length === 0 ||
                instance?.active === false ||
                (instance?.fields?.validate && !_changingValue.current)
              }
            />
          </div>
        ) : null}
      </Form>
    ),
    "vtex-available-apis": (
      <div
        key="vtex-available-apis"
        className="flex flex-col w-full text-justify"
      >
        {typeof instance?.fields?.apiurl !== "undefined" &&
        typeof instance?.fields?.accountid !== "undefined"
          ? getAvailableApis()
          : "Nenhuma api disponível"}
      </div>
    ),
    "teams-programed-excluding": (
      <ProgramedExcluding
        selectedInstance={instance}
        instanceDispatch={reselectTeam}
      />
    ),
    "settings-programed-excluding": (
      <ProgramedExcluding
        selectedInstance={instance}
        instanceDispatch={reselectSettings}
      />
    ),
  };

  const getSectionFromType = () => {
    switch (type) {
      case "profile-info-widget":
      case "profile-info":
      case "user-info":
      case "contact-info":
        return sectionElements["info-users"];
      case "groupchat-info":
        return sectionElements["info-groupchat"];
      case "team-info":
        return sectionElements["info-teams"];
      case "customer-info":
        return sectionElements["info-customers"];
      case "officehour-info":
        return sectionElements["info-officehour"];
      case "holiday-info":
        return sectionElements["info-holiday"];
      case "offlinemessage-info":
        return sectionElements["info-offlinemessage"];
      case "offlinemessage-email":
        return sectionElements["email-offlinemessage"];
      case "offlinemessage-channels":
        return sectionElements["channels-offlinemessage"];
      case "agidesk-info":
        return sectionElements.agidesk;
      case "agidesk-login":
        return sectionElements["agidesk-login"];
      case "agidesk-ticket":
        return sectionElements["agidesk-ticket"];
      case "agidesk-chat-finish":
        return sectionElements["agidesk-chat-finish"];
      case "agidesk-import":
        return sectionElements["agidesk-import"];
      case "survey-teams":
        return sectionElements["survey-teams"];
      case "profile-teams":
      case "user-teams":
        return sectionElements.teams;
      case "profile-customers":
      case "contact-customers":
        return sectionElements.customers;
      case "team-users":
        return sectionElements["team-users"];
      case "customer-contacts":
        return sectionElements["customer-contacts"];
      case "team-chatusers":
        return sectionElements["team-chatusers"];
      case "settings-company":
        return sectionElements["company-info"];
      case "officehours":
        return sectionElements.officehours;
      case "settings-defaultbot":
        return sectionElements.defaultbot;
      case "settings-defaultagent":
        return sectionElements.defaultagent;
      case "settings-officehour":
        return sectionElements["settings-officehour"];
      case "settings-survey":
        return sectionElements["settings-survey"];
      case "settings-offlinemessage":
        return sectionElements["settings-offlinemessage"];
      case "settings-inactivetime":
        return sectionElements.inactivetime;
      case "settings-widget":
        return sectionElements.widget;
      // case "settings-surveyrating":
      //   return sectionElements.surveyrating;
      case "whatsapp-interactive":
        return sectionElements.interactive;
      case "whatsapp-info":
        return sectionElements.whatsapp;
      case "whatsapp-defaultteam":
        return sectionElements["whatsapp-defaultteam"];
      case "whatsapp-offlinemessage":
        return sectionElements["whatsapp-offlinemessage"];
      case "agibot-offlinemessage":
        return sectionElements["agibot-offlinemessage"];
      case "settings-defaultteam":
        return sectionElements["settings-defaultteam"];
      case "agibot-info":
        return sectionElements.agibot;
      case "agibot-phonelist":
        return sectionElements["agibot-phonelist"];
      case "agibot-defaultteam":
        return sectionElements["agibot-defaultteam"];
      case "officehour-teams":
        return sectionElements["officehour-teams"];
      case "holiday-teams":
        return sectionElements["holiday-teams"];
      case "vtex-info":
        return sectionElements.vtex;
      case "survey-content":
        return sectionElements["survey-content"];
      case "survey-info":
        return sectionElements["survey-info"];
      case "vtex-available-apis":
        return sectionElements["vtex-available-apis"];
      case "teams-programed-excluding":
        return sectionElements["teams-programed-excluding"];
      case "settings-programed-excluding":
        return sectionElements["settings-programed-excluding"];
      default:
        return <div />;
    }
  };

  return getSectionFromType();
};

SectionElement.defaultProps = {
  widget: false,
  save: () => null,
  deletedItems: undefined,
  selectedRow: undefined,
  setSelectedRow: undefined,
  setVisible: undefined,
  setShowModal: undefined,
  setShowDeleteModal: undefined,
  setShowChatModal: undefined,
  setChatContact: undefined,
  setChat: undefined,
  surveyRatings: undefined,
  setSelected: undefined,
};

export default SectionElement;
