import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import {
  ExternalAppType,
  selectExternalapp,
  updateExternalapps,
  reselectExternalappBotmaker,
  deleteExternalapps,
  getBotmakerChannels,
} from "../slices/externalapps.slice";
import SidebarComponent from "../shared/components/Sidebars/Sidebar.component";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import { getSettings } from "../slices/settings.slice";
import FinishMessagesSection from "../features/settings/FinishMessages.section";

const BotmakerPage = ({
  disable,
  setDisable,
  toggleSidebar,
  icon,
  title,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingExternalapps, selectedExternalAppBotmaker } = useAppSelector(
    (state) => state.externalapps
  );

  const [toggle, setToggle] = useState<boolean>(
    !!selectedExternalAppBotmaker?.fields?.offlinemessage?._id
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      const { payload } = await dispatch(
        selectExternalapp({
          _id: getId(),
        })
      );

      if (typeof payload?.fields?.offlinemessage?._id !== "undefined") {
        setToggle(true);
      }
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  useEffect(() => {
    const handleClickScroll = () => {
      const element = document.getElementById("agibot-defaultteam");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    switch (location.hash) {
      case "#agibot-defaultteam":
        handleClickScroll();
        break;
      default:
        break;
    }
  }, [location]);

  const goBack = () => {
    navigate("/settings/integration/agibot");
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedExternalAppBotmaker?._id) {
      const { payload } = await dispatch(
        deleteExternalapps([selectedExternalAppBotmaker._id])
      );
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/integration/agibot");
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _externalapp = {
      ...selectedExternalAppBotmaker,
      fields: {
        ...selectedExternalAppBotmaker.fields,
        offlinemessage:
          selectedExternalAppBotmaker?.fields?.offlinemessage?._id,
        defaultteam: selectedExternalAppBotmaker?.fields?.defaultteam?.map(
          (_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }
        ),
      },
      type: ExternalAppType.BOTMAKER,
      deleted: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSaveBotmaker = async () => {
    setDisable(true);
    const _externalapp = {
      ...selectedExternalAppBotmaker,
      _id: selectedExternalAppBotmaker?._id,
      type: ExternalAppType.BOTMAKER,
      active: selectedExternalAppBotmaker?.active,
      fields: {
        ...selectedExternalAppBotmaker.fields,
        name: selectedExternalAppBotmaker?.fields?.name,
        appkey: selectedExternalAppBotmaker?.fields?.appkey,
        apiurl: selectedExternalAppBotmaker?.fields?.apiurl,
        botid: selectedExternalAppBotmaker?.fields?.botid,
        finishmessages: selectedExternalAppBotmaker?.fields?.finishmessages,
        externalphones: selectedExternalAppBotmaker?.fields?.externalphones,
        offlinemessage:
          selectedExternalAppBotmaker?.fields?.offlinemessage?._id,
        defaultteam: selectedExternalAppBotmaker?.fields?.defaultteam?.map(
          (_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }
        ),
      },
    };
    const { payload } = await dispatch(
      updateExternalapps({ _externalapp, _validate: true })
    );
    if (typeof payload.error === "undefined") {
      dispatch(
        reselectExternalappBotmaker({
          ...selectedExternalAppBotmaker,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
      const action = await dispatch(
        getBotmakerChannels({
          _channels: {
            skip: 0,
            limit: 1,
            filter: "",
            externalappid: selectedExternalAppBotmaker?._id || "",
          },
          _validate: true,
        })
      );
      if (typeof action.payload.error === "undefined") {
        const _action = await dispatch(
          updateExternalapps({
            _externalapp: {
              ..._externalapp,
              fields: {
                ..._externalapp.fields,
                validate: true,
              },
            },
            _validate: true,
          })
        );
        if (
          selectedExternalAppBotmaker?.fields?.name &&
          selectedExternalAppBotmaker?.fields?.appkey &&
          selectedExternalAppBotmaker?.fields?.apiurl &&
          selectedExternalAppBotmaker?.fields?.botid
        ) {
          dispatch(
            reselectExternalappBotmaker({
              ...selectedExternalAppBotmaker,
              fields: {
                ...selectedExternalAppBotmaker.fields,
                validate: true,
              },
              updated_at: _action.payload.updated_at,
              updated_by: _action.payload.updated_by,
            })
          );
        }
        navigate(`${location.pathname}#agibot-defaultteam`);
      } else {
        dispatch(
          reselectExternalappBotmaker({
            ...selectedExternalAppBotmaker,
            fields: {
              ...selectedExternalAppBotmaker.fields,
              validate: false,
            },
          })
        );
      }
    }
    setDisable(false);
  };

  const goSave = async () => {
    setDisable(true);
    const _externalapp = {
      _id: selectedExternalAppBotmaker?._id,
      type: ExternalAppType.BOTMAKER,
      active: selectedExternalAppBotmaker?.active,
      fields: {
        name: selectedExternalAppBotmaker?.fields?.name,
        appkey: selectedExternalAppBotmaker?.fields?.appkey,
        apiurl: selectedExternalAppBotmaker?.fields?.apiurl,
        botid: selectedExternalAppBotmaker?.fields?.botid,
        validate: selectedExternalAppBotmaker?.fields?.validate,
        externalphones: selectedExternalAppBotmaker?.fields?.externalphones,
        finishmessages: selectedExternalAppBotmaker?.fields?.finishmessages,
        offlinemessage:
          selectedExternalAppBotmaker?.fields?.offlinemessage?._id,
        defaultteam: selectedExternalAppBotmaker?.fields?.defaultteam?.map(
          (_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }
        ),
      },
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    dispatch(
      reselectExternalappBotmaker({
        ...selectedExternalAppBotmaker,
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
    setDisable(false);
  };

  if (isLoadingExternalapps)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={
          <CreateModal
            title={title}
            icon={icon}
            setShowModal={setShowModal}
            setVisible={setVisible}
          />
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedExternalAppBotmaker?.created_at}
          updated={selectedExternalAppBotmaker?.updated_at}
          deleted={selectedExternalAppBotmaker?.deleted_at}
          title={selectedExternalAppBotmaker?.fields?.name || "-"}
          imported={false}
          icon="las la-robot"
        />
        <Section
          icon="las la-info-circle"
          title="Informações do Agibot"
          description="Preencha os dados necessários para completar a integração"
          component={
            <SectionElement
              instance={selectedExternalAppBotmaker || null}
              type="agibot-info"
              save={goSaveBotmaker}
            />
          }
        />

        {selectedExternalAppBotmaker?.fields?.apiurl?.length > 0 &&
        selectedExternalAppBotmaker?.fields?.appkey?.length > 0 &&
        selectedExternalAppBotmaker?.fields?.botid?.length > 0 &&
        selectedExternalAppBotmaker.fields.validate ? (
          <>
            <DividerInfoPages />
            <Section
              id="agibot-phonelist"
              icon="las la-phone"
              title="Números utilizados na integração"
              description="Indique quais os números que correspondem a esta integração"
              component={
                <SectionElement
                  setVisible={setVisible}
                  instance={selectedExternalAppBotmaker}
                  type="agibot-phonelist"
                  setShowModal={setShowModal}
                />
              }
            />
            <DividerInfoPages />
            <Section
              id="agibot-defaultteam"
              icon="las la-user-friends"
              title="Equipes disponíveis para atendimento"
              description="Selecione as equipes que atenderão as conversas para este APP"
              component={
                <SectionElement
                  setVisible={setVisible}
                  instance={selectedExternalAppBotmaker}
                  type="agibot-defaultteam"
                  setShowModal={setShowModal}
                />
              }
            />
            <DividerInfoPages />
            <Section
              icon="las la-flag-checkered"
              title="Mensagens de encerramento de conversa"
              // description="Personalize as mensagens de encerramento pelo agente, pelo contato e por inatividade"
              description={`Personalize as mensagens de encerramento pelo agente e por inatividade.\n
                <b>Atenção</b>: As mensagens configuradas aqui substituirão as mensagens de encerramento configuradas nas configurações gerais.\n
                Consulte as <b><a class="externalapplink" href="/settings/general">configurações gerais</a></b> ou solicite a um administrador.`}
              component={<FinishMessagesSection type="agibot" />}
            />
            <DividerInfoPages />
            <Section
              id="agibot-offlinemessage"
              icon="las la-hourglass-end"
              title="Atendimentos fora de horário"
              tableStyles="h-min"
              description={`Habilite o atendimento fora de horário e selecione a configuração de perfil de atendimento fora do horário.\n
              Essa configuração definirá a mensagem a ser enviada ao contato ao solicitar um atendimento fora do horário e registrará uma conversa na fila de espera com esse contato.`}
              component={
                <SectionElement
                  instance={selectedExternalAppBotmaker}
                  type="agibot-offlinemessage"
                  toggle={toggle}
                  setToggle={setToggle}
                />
              }
            />
          </>
        ) : null}
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            (toggle &&
              !selectedExternalAppBotmaker?.fields?.offlinemessage?._id) ||
            selectedExternalAppBotmaker.deleted ||
            typeof selectedExternalAppBotmaker?.fields?.name === "undefined" ||
            typeof selectedExternalAppBotmaker?.fields?.appkey ===
              "undefined" ||
            typeof selectedExternalAppBotmaker?.fields?.apiurl ===
              "undefined" ||
            typeof selectedExternalAppBotmaker?.fields?.botid === "undefined" ||
            selectedExternalAppBotmaker?.fields?.name.length < 2 ||
            selectedExternalAppBotmaker?.fields?.appkey.length === 0 ||
            selectedExternalAppBotmaker?.fields?.apiurl.length === 0 ||
            selectedExternalAppBotmaker?.fields?.botid.length === 0
          }
          deleted={selectedExternalAppBotmaker?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedExternalAppBotmaker?.deleted
              ? "Deseja reativar esta integração?"
              : "Deseja remover esta integração?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={goReactive}
          remove={goRemove}
          save={goSave}
        />
        {/* MODALS */}
        {showModal ? (
          <RelationModal
            title="Relacionar equipes"
            icon={icon}
            setShowModal={setShowModal}
          />
        ) : null}
      </Sidebar.Pusher>
    </div>
  );
};

export default BotmakerPage;
