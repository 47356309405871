import React from "react";
import { Form } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import TimezoneDropdown from "../../shared/components/Dropdowns/Timezone.dropdown.component";
import { reselectSettings } from "../../slices/settings.slice";

const TimezoneSection = () => {
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  return (
    <Form>
      <Form.Group widths="equal">
        <TimezoneDropdown
          defaultValue={selectedSettings?.timezone ?? "America/Sao_Paulo"}
          onChange={(e, { value }) => {
            const _value = value?.toString() ?? "America/Sao_Paulo";
            dispatch(
              reselectSettings({
                ...selectedSettings,
                timezone: _value,
              })
            );
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default TimezoneSection;
